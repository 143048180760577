import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Report } from "../../models/report-management.models";

interface ReportPreviewInterventionNoteComponentState {
    report: Report | undefined;
}

@Component({
    selector: "uav-id-shared-lib-report-preview-intervention-note[report]",
    templateUrl: "./report-preview-intervention-note.component.html",
    styleUrls: ["../report-preview-section.scss", "./report-preview-intervention-note.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportPreviewInterventionNoteComponent {
    @Input() public set report(value: Report | undefined) {
        this.localStore.patchState({ report: value });
    }

    @Output() public readonly dialogOpen = new EventEmitter<void>();

    protected readonly report$ = this.localStore.selectByKey("report");

    constructor(private readonly localStore: LocalComponentStore<ReportPreviewInterventionNoteComponentState>) {
        this.localStore.setState({
            report: undefined,
        });
    }
}
