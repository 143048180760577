import { InjectionToken } from "@angular/core";

export interface ReportManagementEndpoints {
    getReports: string;
    getReportSummary: string;
    getActiveOfficers: string;
    getOfficers: string;
    updateAssignedOfficer: string;
    updateNote: string;
    updateStatus: string;
    updateOfficerUnit: string;
    getReportGeoZoneDetails: string;
}

export const REPORT_MANAGEMENT_ENDPOINTS = new InjectionToken<ReportManagementEndpoints>("Report management endpoints");
