import { HttpParams } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import { LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { DialogService } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    Report,
    ReportGeoZoneDialogComponent,
    ReportGeoZoneInfo,
    ReportInterventionNoteDialogService,
    ReportManagementActions,
    ReportManagementState,
} from "@dtm-frontend/uav-identification-shared-lib/report";
import { StatusChange } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, switchMap, tap } from "rxjs";
import { OfficerAssignDialogService } from "../../dialogs/officer-assign-dialog/officer-assign-dialog.service";

@UntilDestroy()
@Component({
    selector: "uav-id-admin-lib-report-preview",
    templateUrl: "./report-preview.component.html",
    styleUrls: ["./report-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportPreviewComponent {
    protected readonly isProcessing$ = this.store.select(ReportManagementState.isProcessing);
    protected readonly hasGetReportSummaryErrorOccurred$ = this.store.select(ReportManagementState.hasGetReportSummaryErrorOccurred);
    protected readonly reportSummary$ = this.store.select(ReportManagementState.reportSummary);
    protected readonly selectedReportGeoZonesInfo$ = this.store.select(ReportManagementState.selectedReportGeoZonesInfo);
    protected readonly selectedReportGeoZonesInfoError$ = this.store.select(ReportManagementState.selectedReportGeoZonesInfoError);
    protected readonly isSelectedReportGeoZonesInfoLoading$ = this.store.select(ReportManagementState.isSelectedReportGeoZonesInfoLoading);

    protected readonly REPORT_GEO_ZONES_LAYER_CONFIG: LeafletMapLayerConfig = {
        type: "WMS",
        baseUrl: this.sharedMapEndpoints.geoServerEndpoint,
        options: {
            layers: "uav-identification-admin",
            format: "image/png",
            transparent: true,
        },
    };

    constructor(
        private readonly dialogService: DialogService,
        private readonly interventionNoteDialogService: ReportInterventionNoteDialogService,
        private readonly officerAssignDialogService: OfficerAssignDialogService,
        @Inject(SHARED_MAP_ENDPOINTS) private readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {}

    protected async updateStatus(statusChange: StatusChange, report: Report) {
        await firstValueFrom(
            this.store
                .dispatch(new ReportManagementActions.UpdateReportStatus({ id: report.id, ...statusChange }))
                .pipe(untilDestroyed(this))
        );

        const error = this.store.selectSnapshot(ReportManagementState.reportManagementError);

        if (error) {
            this.toastrService.error(this.translocoService.translate("uavIdAdminLibReportMgmt.reportPreview.statusUpdateUnknownError"));

            return;
        }

        this.toastrService.success(this.translocoService.translate("uavIdAdminLibReportMgmt.reportPreview.statusUpdateSuccessMessage"));
        this.reloadData(report.id);
    }

    protected async openAssignOfficerDialog(report: Report) {
        const afterDialogSubmit = await this.officerAssignDialogService.openAssignOfficerDialog(report);

        afterDialogSubmit
            ?.pipe(
                tap(() => this.reloadData(report.id)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected openInterventionNoteDialog(data: Report | undefined) {
        if (!data) {
            return;
        }

        this.interventionNoteDialogService
            .openDialog(data)
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                tap(() => this.reloadData(data.id)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected getSelectedZonesInfo(params: HttpParams): void {
        this.store.dispatch(new ReportManagementActions.GetReportGeoZonesInfo(params));
    }

    private reloadData(reportId: string) {
        this.store.dispatch(new ReportManagementActions.GetReportSummary(reportId));
    }

    protected openZoneDetailsDialog(zone: ReportGeoZoneInfo): void {
        this.store
            .dispatch(new ReportManagementActions.GetReportGeoZoneDetails(zone.id))
            .pipe(
                switchMap(() =>
                    this.dialogService
                        .open(ReportGeoZoneDialogComponent, {
                            data: {
                                zone,
                                details: this.store.selectSnapshot(ReportManagementState.selectedReportGeoZoneDetails),
                                hasError: this.store.selectSnapshot(ReportManagementState.selectedReportGeoZoneDetailsError),
                            },
                        })
                        .afterClosed()
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
