import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ComponentRef, Directive, Inject, Input, OnDestroy, ViewContainerRef } from "@angular/core";
import { MILLISECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FeatureGroup, Icon, IconOptions, Map, Marker, Polyline, PolylineOptions, PopupOptions } from "leaflet";
import { RemoteId } from "remote-id";
import { Subject, interval } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "../../../leaflet/leaflet-map.tokens";
import { LeafletRemoteIdLayerOperatorDetailsComponent } from "./leaflet-remote-id-layer-operator-details/leaflet-remote-id-layer-operator-details.component";
import { LeafletRemoteIdLayerUavDetailsComponent } from "./leaflet-remote-id-layer-uav-details/leaflet-remote-id-layer-uav-details.component";

interface RemoteIdDataRecord {
    macAddress: string;
    uavMarker: Marker;
    uavDetailsComponent: ComponentRef<LeafletRemoteIdLayerUavDetailsComponent>;
    operatorMarker: Marker;
    operatorDetailsComponent: ComponentRef<LeafletRemoteIdLayerOperatorDetailsComponent>;
    markerConnector: Polyline;
    lastUpdate: Date;
}

const UAV_MARKER_ICON_OPTIONS: IconOptions = {
    iconUrl: "assets/images/remote-id/uav.svg",
    /* eslint-disable no-magic-numbers */
    iconSize: [60, 60],
    iconAnchor: [30, 15],
};
const OPERATOR_MARKER_ICON_OPTIONS: IconOptions = {
    iconUrl: "assets/images/remote-id/operator.svg",
    iconSize: [40, 40],
    iconAnchor: [20, 10],
    /* eslint-enable */
};
// NOTE: $color-gray-900: #061636
const MARKER_CONNECTOR_POLYLINE_OPTIONS: PolylineOptions = { dashArray: "5", color: "#061636" };

const DETAILS_POPUP_OPTIONS: PopupOptions = {
    closeButton: false,
    className: "info-popup",
    minWidth: 300,
    maxWidth: 300,
};

const OBSOLESCENCE_TIMEOUT_IN_MINUTES = 2;
const REMOVAL_TIMEOUT_IN_MINUTES = 10;
const ACTIVE_MARKER_OPACITY = 1;
const OBSOLETE_MARKER_OPACITY = 0.3;

@UntilDestroy()
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: "dtm-map-leaflet-remote-id-layer" })
export class LeafletRemoteIdLayerDirective implements OnDestroy {
    @Input() public set data(value: Array<Partial<RemoteId.Data>> | undefined) {
        if (!value) {
            return;
        }

        this.displayRemoteIdData(value);
    }

    @Input() public set shouldRemoveObsoleteRecords(value: BooleanInput) {
        const shouldRemove = coerceBooleanProperty(value);

        if (shouldRemove) {
            this.removeObsoleteRecords();

            return;
        }

        this.clearIntervalSubject.next();
    }

    private map: Map | undefined;
    private dataRecords: Partial<RemoteIdDataRecord>[] = [];
    private readonly remoteIdLayer: FeatureGroup = new FeatureGroup();
    private readonly clearIntervalSubject = new Subject<void>();

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly viewContainerRef: ViewContainerRef
    ) {}

    public ngOnDestroy() {
        this.dataRecords.forEach((record) => {
            record.uavDetailsComponent?.destroy();
            record.operatorDetailsComponent?.destroy();
        });
    }

    private async displayRemoteIdData(dataset: Array<Partial<RemoteId.Data>>): Promise<void> {
        if (!this.map) {
            this.map = await this.mapProvider.getMap();
            this.map.addLayer(this.remoteIdLayer);
        }

        dataset.forEach((data) => {
            const macAddress = data.extraInfo?.macAddress;
            if (!macAddress) {
                return;
            }

            const record = this.dataRecords.find((dataRecord) => dataRecord.macAddress === macAddress) ?? {};
            this.displayRecord(record, data);
        });
    }

    private displayRecord(record: Partial<RemoteIdDataRecord>, data: Partial<RemoteId.Data>): void {
        record.lastUpdate = new Date();
        this.resetMarkersVisibility(record);

        this.createOrUpdateUavDetails(record, data);
        this.createOrUpdateUavMarker(record, data);

        this.createOrUpdateOperatorDetails(record, data);
        this.createOrUpdateOperatorMarker(record, data);

        this.createOrUpdateMarkerConnector(record);

        if (!record?.macAddress) {
            record.macAddress = data.extraInfo?.macAddress;

            this.dataRecords.push(record);
        }
    }

    private createOrUpdateUavDetails(record: Partial<RemoteIdDataRecord>, data: Partial<RemoteId.Data>): void {
        if (!record.uavDetailsComponent) {
            record.uavDetailsComponent = this.viewContainerRef.createComponent(LeafletRemoteIdLayerUavDetailsComponent);
            record.uavDetailsComponent.instance.data = data;
            record.uavDetailsComponent.changeDetectorRef.detectChanges();

            return;
        }

        record.uavDetailsComponent.instance.data = { ...record.uavDetailsComponent.instance.data, ...data };
        record.uavDetailsComponent.changeDetectorRef.detectChanges();
    }

    private createOrUpdateUavMarker(record: Partial<RemoteIdDataRecord>, data: Partial<RemoteId.Data>): void {
        const uavPosition = data.location?.position;
        if (!uavPosition) {
            return;
        }

        if (!record.uavMarker) {
            record.uavMarker = new Marker([uavPosition.latitude, uavPosition.longitude], {
                icon: new Icon(UAV_MARKER_ICON_OPTIONS),
            }).addTo(this.remoteIdLayer);

            if (record.uavDetailsComponent) {
                record.uavMarker.bindPopup(record.uavDetailsComponent.location.nativeElement, DETAILS_POPUP_OPTIONS);
            }

            return;
        }

        record.uavMarker.setLatLng([uavPosition.latitude, uavPosition.longitude]);
    }

    private createOrUpdateOperatorDetails(record: Partial<RemoteIdDataRecord>, data: Partial<RemoteId.Data>): void {
        if (!record.operatorDetailsComponent) {
            record.operatorDetailsComponent = this.viewContainerRef.createComponent(LeafletRemoteIdLayerOperatorDetailsComponent);
            record.operatorDetailsComponent.instance.data = data;
            record.operatorDetailsComponent.changeDetectorRef.detectChanges();

            return;
        }

        record.operatorDetailsComponent.instance.data = { ...record.operatorDetailsComponent.instance.data, ...data };
        record.operatorDetailsComponent.changeDetectorRef.detectChanges();
    }

    private createOrUpdateOperatorMarker(record: Partial<RemoteIdDataRecord>, data: Partial<RemoteId.Data>): void {
        const operatorPosition = data.systemData?.operatorPosition;
        if (!operatorPosition) {
            return;
        }

        if (!record.operatorMarker) {
            record.operatorMarker = new Marker([operatorPosition.latitude, operatorPosition.longitude], {
                icon: new Icon(OPERATOR_MARKER_ICON_OPTIONS),
            }).addTo(this.remoteIdLayer);

            if (record.operatorDetailsComponent) {
                record.operatorMarker.bindPopup(record.operatorDetailsComponent.location.nativeElement, DETAILS_POPUP_OPTIONS);
            }

            return;
        }

        record.operatorMarker.setLatLng([operatorPosition.latitude, operatorPosition.longitude]);
    }

    private createOrUpdateMarkerConnector(record: Partial<RemoteIdDataRecord>) {
        if (!record.uavMarker || !record.operatorMarker) {
            return;
        }

        if (!record.markerConnector) {
            record.markerConnector = new Polyline(
                [record.uavMarker.getLatLng(), record.operatorMarker.getLatLng()],
                MARKER_CONNECTOR_POLYLINE_OPTIONS
            ).addTo(this.remoteIdLayer);

            return;
        }

        record.markerConnector.setLatLngs([record.uavMarker.getLatLng(), record.operatorMarker.getLatLng()]);
    }

    private resetMarkersVisibility(record: Partial<RemoteIdDataRecord>): void {
        const { uavMarker, operatorMarker, markerConnector } = record;

        uavMarker?.setOpacity(ACTIVE_MARKER_OPACITY);
        operatorMarker?.setOpacity(ACTIVE_MARKER_OPACITY);
        markerConnector?.setStyle({ opacity: ACTIVE_MARKER_OPACITY });
    }

    private removeObsoleteRecords(): void {
        this.clearIntervalSubject.next();

        interval(MILLISECONDS_IN_MINUTE)
            .pipe(takeUntil(this.clearIntervalSubject), untilDestroyed(this))
            .subscribe(() => {
                const currentTimestamp = new Date().getTime();

                this.dataRecords.forEach((processedRecord) => {
                    if (!processedRecord.lastUpdate) {
                        return;
                    }

                    const lifespanInMinutes = (currentTimestamp - processedRecord.lastUpdate.getTime()) / MILLISECONDS_IN_MINUTE;

                    if (lifespanInMinutes > REMOVAL_TIMEOUT_IN_MINUTES) {
                        processedRecord.uavMarker?.remove();
                        processedRecord.operatorMarker?.remove();
                        processedRecord.markerConnector?.remove();

                        this.dataRecords = this.dataRecords.filter((dataRecord) => dataRecord.macAddress !== processedRecord.macAddress);

                        return;
                    }

                    if (lifespanInMinutes > OBSOLESCENCE_TIMEOUT_IN_MINUTES) {
                        processedRecord.uavMarker?.setOpacity(OBSOLETE_MARKER_OPACITY);
                        processedRecord.operatorMarker?.setOpacity(OBSOLETE_MARKER_OPACITY);
                        processedRecord.markerConnector?.setStyle({ opacity: OBSOLETE_MARKER_OPACITY });
                    }
                });
            });
    }
}
