import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { OfficerEntity, OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Observable, catchError, map, throwError } from "rxjs";
import { OfficerErrorType, OfficerList } from "../models/officer.models";
import { OFFICER_ENDPOINTS, OfficerEndpoints } from "../officer.tokens";
import {
    OfficerListResponseBody,
    convertOfficerListRequestParamsToRequestParams,
    convertOfficerListResponseBodyToOfficerList,
} from "./officer-api.converters";

@Injectable()
export class OfficerApiService {
    constructor(@Inject(OFFICER_ENDPOINTS) private readonly endpoints: OfficerEndpoints, private readonly http: HttpClient) {}

    public getOfficerData(officerId: string): Observable<OfficerEntity> {
        return this.http
            .get<OfficerEntity>(StringUtils.replaceInTemplate(this.endpoints.getOfficerData, { officerId }))
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }

    public getOfficers(queryParams: Params = {}): Observable<OfficerList> {
        return this.http
            .get<OfficerListResponseBody>(this.endpoints.getOfficers, {
                params: convertOfficerListRequestParamsToRequestParams(queryParams),
            })
            .pipe(
                map((response) => convertOfficerListResponseBodyToOfficerList(response)),
                catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown })))
            );
    }

    public getAllOfficerUnits(): Observable<OfficerUnitEntity[]> {
        return this.http
            .get<OfficerUnitEntity[]>(this.endpoints.getAllOfficerUnits)
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }

    public getUniformedServiceOfficerUnits(uniformedServiceId: string): Observable<OfficerUnitEntity[]> {
        return this.http
            .get<OfficerUnitEntity[]>(StringUtils.replaceInTemplate(this.endpoints.getUniformedServiceOfficerUnits, { uniformedServiceId }))
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }

    public activateOfficer(officerId: string, officerUnit?: OfficerUnitEntity): Observable<OfficerUnitEntity> {
        const body = officerUnit ? { officerUnit } : {};

        return this.http
            .put<OfficerUnitEntity>(StringUtils.replaceInTemplate(this.endpoints.activateOfficer, { officerId }), body)
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }

    public deactivateOfficer(officerId: string): Observable<OfficerUnitEntity> {
        return this.http
            .put<OfficerUnitEntity>(StringUtils.replaceInTemplate(this.endpoints.deactivateOfficer, { officerId }), null)
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }

    public rejectOfficer(officerId: string): Observable<OfficerUnitEntity> {
        return this.http
            .put<OfficerUnitEntity>(StringUtils.replaceInTemplate(this.endpoints.rejectOfficer, { officerId }), null)
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }

    public promoteOfficer(officerId: string): Observable<OfficerUnitEntity> {
        return this.http
            .put<OfficerUnitEntity>(StringUtils.replaceInTemplate(this.endpoints.promoteOfficer, { officerId }), null)
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }

    public demoteOfficer(officerId: string): Observable<OfficerUnitEntity> {
        return this.http
            .put<OfficerUnitEntity>(StringUtils.replaceInTemplate(this.endpoints.demoteOfficer, { officerId }), null)
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }

    public updateOfficerUnit(officerId: string, officerUnit: OfficerUnitEntity): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateOfficerUnit, { officerId }), { officerUnit })
            .pipe(catchError(() => throwError(() => ({ type: OfficerErrorType.Unknown }))));
    }
}
