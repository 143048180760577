import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { REPORT_STATUS_BADGE_CONFIGURATION, ReportListItem, ReportStatusChange } from "@dtm-frontend/uav-identification-shared-lib/report";
import { StatusChange, UserEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { UntilDestroy } from "@ngneat/until-destroy";

interface ReportListComponentState {
    isProcessing: boolean;
    hasGetListErrorOccurred: boolean;
    reports: ReportListItem[];
    reportsPage: Page | undefined;
    sort: Sort | undefined;
    displayedTableColumns: Array<keyof ReportListItem | "actions">;
    hasExtendedAccess: boolean;
}

@UntilDestroy()
@Component({
    selector: "uav-id-admin-lib-report-list[reports][reportsPage]",
    templateUrl: "./report-list.component.html",
    styleUrls: ["./report-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportListComponent implements AfterViewInit {
    @ViewChild(MatSort) private matSort!: MatSort;

    @Input() public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: value });
    }
    @Input() public set hasGetListErrorOccurred(value: boolean | undefined) {
        this.localStore.patchState({ hasGetListErrorOccurred: !!value });
    }
    @Input() public set reports(value: ReportListItem[] | undefined) {
        this.localStore.patchState({ reports: value ?? [] });
    }
    @Input() public set reportsPage(value: Page | undefined) {
        this.localStore.patchState({ reportsPage: value });
    }
    @Input() public set sort(value: Sort | undefined) {
        this.localStore.patchState({ sort: value });

        if (value && this.matSort) {
            this.matSort.active = value.active;
            this.matSort.direction = value.direction;
        }
    }
    @Input() public set hasExtendedAccess(value: boolean | undefined) {
        value = coerceBooleanProperty(value);

        this.localStore.patchState({
            hasExtendedAccess: value,
            displayedTableColumns: this.appendActionsColumn(
                value ? this.extendedAccessDisplayedTableColumns : this.defaultDisplayedTableColumns
            ),
        });
    }

    @Output() protected readonly interventionDialogOpen = new EventEmitter<ReportListItem>();
    @Output() protected readonly officerAssign = new EventEmitter<ReportListItem>();
    @Output() protected readonly officerUnitChange = new EventEmitter<ReportListItem>();
    @Output() protected readonly statusChange = new EventEmitter<ReportStatusChange>();
    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();
    @Output() protected readonly sortChange = new EventEmitter<Sort>();
    @Output() protected readonly pageReload = new EventEmitter();

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly reports$ = this.localStore.selectByKey("reports");
    protected readonly reportsPage$ = this.localStore.selectByKey("reportsPage");
    protected readonly hasGetListErrorOccurred$ = this.localStore.selectByKey("hasGetListErrorOccurred");
    protected readonly displayedTableColumns$ = this.localStore.selectByKey("displayedTableColumns");
    protected readonly hasExtendedAccess$ = this.localStore.selectByKey("hasExtendedAccess");

    protected readonly REPORT_STATUS_BADGE_CONFIGURATION = REPORT_STATUS_BADGE_CONFIGURATION;
    protected readonly defaultDisplayedTableColumns: Array<keyof ReportListItem> = [
        "status",
        "assignedOfficer",
        "number",
        "reportedAt",
        "phoneNumber",
        "location",
    ];
    protected readonly extendedAccessDisplayedTableColumns: Array<keyof ReportListItem> = [
        ...this.defaultDisplayedTableColumns,
        "officerUnit",
    ];

    constructor(private readonly localStore: LocalComponentStore<ReportListComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            hasGetListErrorOccurred: false,
            reports: [],
            reportsPage: undefined,
            sort: undefined,
            displayedTableColumns: this.appendActionsColumn(this.defaultDisplayedTableColumns),
            hasExtendedAccess: false,
        });
    }

    public ngAfterViewInit() {
        this.setInitialSort();
    }

    protected updateStatus(change: StatusChange, { id }: ReportListItem): void {
        this.statusChange.emit({ id, ...change });
    }

    protected displayOfficerFullName(officer?: UserEntity): string {
        return officer ? `${officer.firstName} ${officer.lastName}` : "-";
    }

    private setInitialSort(): void {
        const initialSort = this.localStore.selectSnapshotByKey("sort");

        if (!initialSort) {
            return;
        }

        this.matSort.active = initialSort.active;
        this.matSort.direction = initialSort.direction;
    }

    private appendActionsColumn(columns: Array<keyof ReportListItem>): Array<keyof ReportListItem | "actions"> {
        return [...columns, "actions"];
    }
}
