import { WGS84Coordinates } from "@dtm-frontend/shared/map/leaflet";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { OfficerUnitEntity, UserEntity } from "../../shared/models/user.models";

export enum ReportUavHeightEstimation {
    AroundFive = "AROUND_FIVE",
    AroundTen = "AROUND_TEN",
    AroundTwentyFive = "AROUND_TWENTY_FIVE",
    AboveFifty = "ABOVE_FIFTY",
    CantDetermine = "CANNOT_DETERMINE",
}

export enum ReportStatus {
    New = "NEW",
    UnderAnalysis = "UNDER_ANALYSIS",
    Completed = "COMPLETED",
    Rejected = "REJECTED",
    Unconfirmed = "UNCONFIRMED",
    FurtherInvestigationRequired = "FURTHER_INVESTIGATION_REQUIRED",
}

export enum ReportReason {
    NotVisibleOnMap = "NOT_VISIBLE_ON_MAP",
    DangerousManeuvers = "DANGEROUS_MANEUVERS",
    FlightOverPrivateArea = "FLIGHT_OVER_PRIVATE_AREA",
    IllegalRecording = "ILLEGAL_RECORDING",
    CrashedUav = "CRASHED_UAV",
    HealthOrPropertyDamage = "HEALTH_OR_PROPERTY_DAMAGE",
    Other = "OTHER",
}

export const EMERGENCY_REPORT_REASONS = [ReportReason.CrashedUav, ReportReason.HealthOrPropertyDamage];

export interface ReportEntity {
    id: string;
    number: string;
    status: ReportStatus;
    createdAt: Date;
    uav: ReportUavEntity;
    pilot: ReportPilotEntity;
    reportingUser: ReportingUserEntity;
    reasons: ReportReason[];
    otherReasons: string;
    interventionNote: string;
    interveningOfficer: UserEntity;
    officerUnit: OfficerUnitEntity;
}

export interface ReportPilotEntity {
    visible: boolean;
    appearance: string;
}

export interface ReportUavEntity {
    location: WGS84Coordinates;
    height: string;
}

export interface ReportingUserEntity {
    id: string;
    firstName: string;
    lastName: string;
    location: WGS84Coordinates;
    phoneNumber: PhoneNumber;
    address: string;
    officer: boolean;
    statistics: {
        reportCount: number;
        rejectedReportCount: number;
    };
}

export interface InterventionNoteDialogData {
    id: string;
    number: string;
    status: ReportStatus;
    interventionNote: string;
    minNumberOfRows?: number;
}
