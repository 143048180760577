import { TileLayerOptions, WMSOptions } from "leaflet";

export interface LeafletMapLayerConfig {
    type: "TileLayer" | "WMS";
    baseUrl: string;
    options: CustomTileLayerOptions | CustomWMSOptions;
    authorizationHeader?: string;
}

export interface CustomTileLayerOptions extends TileLayerOptions {
    CQL_FILTER?: string;
}

export interface CustomWMSOptions extends WMSOptions {
    CQL_FILTER?: string;
}

export enum TilesetStyle {
    BaseRoad = "BaseRoad",
    ImageryBaseHybridRoad = "ImageryBaseHybridRoad",
}
