import { Params } from "@angular/router";
import { MIN_PAGE_SIZE_VALUE, PageResponseBody } from "@dtm-frontend/shared/ui";
import { RemoteId } from "remote-id";
import { Flight, FlightSearchArea } from "../../flight/models/flight.models";
import { UserEntity } from "../../shared/models/user.models";
import {
    ReportGeoZoneDetails,
    ReportGeoZoneInfo,
    ReportList,
    ReportListItem,
    ReportListQueryParams,
    ReportStatusChange,
    ReportSummary,
} from "../models/report-management.models";
import { EMERGENCY_REPORT_REASONS, ReportEntity } from "../models/report-shared.models";

export type GetOfficersResponseBody = UserEntity[];
export type ReportListResponseBody = PageResponseBody<ReportEntity>;
export type ReportStatusUpdateResponseBody = ReportListItem;
export type ReportUpdateAssignedOfficerResponseBody = ReportListItem;
export type ReportInterventionNoteUpdateResponseBody = ReportListItem;
export type ReportUpdateOfficerUnitResponseBody = ReportListItem;

export interface ReportSummaryResponseBody {
    report: ReportEntity;
    flightSearchResult: {
        flights: Flight[];
        circle: FlightSearchArea;
    };
    remoteIds: Array<Partial<RemoteId.Data>>;
}

export interface ReportStatusUpdateRequestPayload {
    status: string;
}

export interface GetReportGeoZonesInfoResponseBody {
    features: Array<{
        id: string;
        properties: {
            designator: string;
            type: string;
            name: string;
            zone_volume_altitude_range_ceiling: number;
            zone_volume_altitude_range_floor: number;
            zone_volume_altitude_range_formatted: string;
        };
    }>;
}

export interface GetReportGeoZoneDetailsResponseBody {
    contact: string;
    description: string;
    flightConditions: string;
    languageTag: string;
}

export function convertReportListResponseBodyToReportList(response: ReportListResponseBody): ReportList {
    return {
        content: response.content.map((report) => ({
            id: report.id,
            number: report.number,
            status: report.status,
            reportedAt: report.createdAt,
            phoneNumber: report.reportingUser.phoneNumber,
            location: report.reportingUser.address,
            isCreatedByOfficer: report.reportingUser.officer,
            isEmergencyReport: report.reasons.some((reason) => EMERGENCY_REPORT_REASONS.includes(reason)),
            interventionNote: report.interventionNote,
            assignedOfficer: report.interveningOfficer,
            officerUnit: report.officerUnit,
        })),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

function getSortPropertyName(active: string | undefined): string | undefined {
    switch (active) {
        case "officerUnit":
            return "officerUnit.name";
        case "reportedAt":
            return "createdAt";
        default:
            return active;
    }
}

export function convertReportListQueryParamsToReportRequestQueryParams(queryParams: Partial<ReportListQueryParams>): Params {
    const {
        text,
        statuses,
        isCreatedByOfficer,
        isEmergencyReport,
        createdDateFrom,
        createdDateTo,
        page,
        size,
        active,
        direction,
        officerUnits,
    } = queryParams ?? {};
    const sort = `${getSortPropertyName(active)},${direction}`;
    let to;

    if (createdDateTo) {
        const updatedDateTo = new Date(createdDateTo);
        updatedDateTo.setDate(updatedDateTo.getDate() + 1);
        to = updatedDateTo.toISOString();
    }

    return {
        ...(text && { param: text }),
        ...(statuses && { statuses }),
        ...(isCreatedByOfficer && { createdByOfficer: isCreatedByOfficer }),
        ...(isEmergencyReport && { definedReasons: EMERGENCY_REPORT_REASONS.join(",") }),
        ...(createdDateFrom && { from: createdDateFrom }),
        ...(active && direction && { sort }),
        ...(to && { to }),
        ...(officerUnits && { officerUnitsIds: officerUnits }),
        page: page ?? 0,
        size: size ?? MIN_PAGE_SIZE_VALUE,
    };
}

export function convertReportStatusChangeToReportStatusUpdateRequestPayload(
    statusChange: ReportStatusChange
): ReportStatusUpdateRequestPayload {
    return {
        status: statusChange.newStatus,
    };
}

export function convertReportSummaryResponseBodyToReportSummary(reportSummaryResponse: ReportSummaryResponseBody): ReportSummary {
    const { report, flightSearchResult, remoteIds } = reportSummaryResponse ?? {};

    return {
        report: {
            id: report.id,
            number: report.number,
            status: report.status,
            createdAt: report.createdAt,
            address: report.reportingUser.address,
            uav: report.uav,
            pilot: {
                isVisible: report.pilot.visible,
                description: report.pilot.appearance,
            },
            userLocation: {
                latitude: report.reportingUser.location.latitude,
                longitude: report.reportingUser.location.longitude,
            },
            reasons: report.reasons,
            otherReasons: report.otherReasons,
            flightsNumber: flightSearchResult.flights?.length,
            flightsSearchRadius: flightSearchResult.circle.radius,
            interventionNote: report.interventionNote,
            assignedOfficer: report.interveningOfficer,
            officerUnit: report.officerUnit,
            reportingUser: {
                phoneNumber: report.reportingUser?.phoneNumber,
                ...(report.reportingUser?.firstName &&
                    report.reportingUser?.lastName && { fullName: `${report.reportingUser.firstName} ${report.reportingUser.lastName}` }),
                numberOfReports: report.reportingUser.statistics.reportCount,
                numberOfRejectedReports: report.reportingUser.statistics.rejectedReportCount,
            },
            isCreatedByOfficer: report.reportingUser.officer,
            isEmergencyReport: report.reasons.some((reason) => EMERGENCY_REPORT_REASONS.includes(reason)),
        },
        flights: flightSearchResult.flights,
        remoteIds,
    };
}

export function convertGetReportGeoZonesInfoResponseBodyToReportGeoZoneInfoList(
    response: GetReportGeoZonesInfoResponseBody
): ReportGeoZoneInfo[] {
    return response.features.map(({ id, properties: { designator, type, name, zone_volume_altitude_range_formatted } }) => ({
        id: id.split(".")[1], // NOTE: Required to get UUID - received value from geoserver has format of project_id.UUID
        designator,
        type: `${type} / ${name}`,
        heightRange: zone_volume_altitude_range_formatted,
    }));
}

export function convertGetReportGeoZoneDetailsResponseBodyToReportGeoZoneDetails(
    response: GetReportGeoZoneDetailsResponseBody
): ReportGeoZoneDetails {
    return {
        contact: response.contact?.trim(),
        description: response.description?.trim(),
    };
}
