<ng-container *ngrxLet="isNoteProcessing$ as isNoteProcessing">
    <div class="dialog-header main-header">
        <h2 mat-dialog-title>
            <ng-container *ngIf="!data.interventionNote; else editTitle">
                {{ "uavIdSharedLibReport.interventionNoteDialog.headerAddLabel" | transloco }}
            </ng-container>

            <ng-template #editTitle>
                {{ "uavIdSharedLibReport.interventionNoteDialog.headerEditLabel" | transloco }}
            </ng-template>
        </h2>
        <button type="button" class="button-icon" [disabled]="isNoteProcessing" (click)="closeDialog()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div class="dialog-header sub-header">
        <h3>{{ "uavIdSharedLibReport.interventionNoteDialog.reportNumberLabel" | transloco : { value: data.number } }}</h3>
        <uav-id-shared-lib-status-badge
            [configuration]="REPORT_STATUS_BADGE_CONFIGURATION"
            [status]="data.status"
        ></uav-id-shared-lib-status-badge>
    </div>

    <div mat-dialog-content>
        <dtm-ui-textarea-field [maxLength]="NOTE_MAX_LENGTH">
            <label>{{ "uavIdSharedLibReport.interventionNoteDialog.noteLabel" | transloco }}</label>
            <textarea matInput [rows]="data.minNumberOfRows" [formControl]="noteControl"></textarea>
            <div class="field-error" *dtmUiFieldHasError="noteControl; name: 'maxlength'; error as error">
                {{ "uavIdSharedLibReport.interventionNoteDialog.noteMaxLengthError" | transloco : { maxLength: error.requiredLength } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="noteControl; name: 'onlyWhitespacesNotAllowed'; error as error">
                {{ "uavIdSharedLibReport.interventionNoteDialog.noteOnlyWhitespacesNotAllowedError" | transloco }}
            </div>
        </dtm-ui-textarea-field>
    </div>

    <div mat-dialog-actions class="dialog-actions">
        <button type="button" class="button-secondary" [disabled]="isNoteProcessing" (click)="closeDialog()">
            {{ "uavIdSharedLibReport.interventionNoteDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="submit" class="button-primary" [disabled]="noteControl.invalid || isNoteProcessing" (click)="submit()">
            <dtm-ui-icon *ngIf="!isNoteProcessing" name="save"></dtm-ui-icon>
            <mat-spinner *ngIf="isNoteProcessing" diameter="20" color="accent"></mat-spinner>
            <span>{{ "uavIdSharedLibReport.interventionNoteDialog.confirmButtonLabel" | transloco }}</span>
        </button>
    </div>
</ng-container>
