import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Sort } from "@angular/material/sort";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { DialogService, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, Logger, RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    InvolvementType,
    ReportInterventionNoteDialogService,
    ReportListFilters,
    ReportListItem,
    ReportManagementActions,
    ReportManagementState,
    ReportStatus,
    ReportStatusChange,
} from "@dtm-frontend/uav-identification-shared-lib/report";
import { OfficerUnitEntity, UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, firstValueFrom, map, switchMap, tap } from "rxjs";
import { OfficerActions } from "../../../officer/state/officer.actions";
import { OfficerState } from "../../../officer/state/officer.state";
import { AssignOfficerUnitDialogComponent } from "../../../shared/components/assign-officer-unit-dialog/assign-officer-unit-dialog.component";
import { OfficerAssignDialogService } from "../../dialogs/officer-assign-dialog/officer-assign-dialog.service";

interface ReportListContainerComponentState {
    statuses: ReportStatus[];
    initialFilters: ReportListFilters;
}

@UntilDestroy()
@Component({
    selector: "uav-id-admin-lib-report-list-container",
    templateUrl: "./report-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportListContainerComponent {
    protected readonly hasExtendedAccess$ = this.store.select(AuthState.roles).pipe(map((roles) => roles?.includes(UavIdRole.Admin)));
    protected readonly reports$ = this.store.select(ReportManagementState.reports);
    protected readonly reportsPage$ = this.store.select(ReportManagementState.reportsPage);
    protected readonly isProcessing$ = this.store.select(ReportManagementState.isProcessing);
    protected readonly hasGetListErrorOccurred$ = this.store.select(ReportManagementState.hasGetListErrorOccurred);
    protected readonly uniformedServiceOfficerUnits$ = this.store.select(OfficerState.uniformedServiceOfficerUnits);
    protected readonly statuses$ = this.localStore.selectByKey("statuses");
    protected readonly listSorting$ = this.route.queryParams.pipe(
        map(({ active, direction }) => ({ active, direction })),
        distinctUntilChanged()
    );
    protected readonly initialFilters$ = this.localStore.selectByKey("initialFilters");

    constructor(
        private readonly dialogService: DialogService,
        private readonly interventionNoteDialogService: ReportInterventionNoteDialogService,
        private readonly localStore: LocalComponentStore<ReportListContainerComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService,
        private readonly officerAssignDialogService: OfficerAssignDialogService
    ) {
        this.store.dispatch(new OfficerActions.GetAllOfficerUnits());
        this.store.dispatch(new OfficerActions.GetUniformedServiceOfficerUnits());

        const {
            text = null,
            statuses = null,
            isCreatedByOfficer = null,
            isEmergencyReport = null,
            createdDateFrom = null,
            createdDateTo = null,
            officerUnits = null,
        } = this.route.snapshot.queryParams ?? {};

        this.localStore.setState({
            statuses: Object.values(ReportStatus) as ReportStatus[],
            initialFilters: {
                text,
                statuses: statuses?.split(",") ?? [],
                isCreatedByOfficer,
                isEmergencyReport,
                createdDateFrom,
                createdDateTo,
                officerUnits,
            },
        });
        this.updateReportListOnQueryParamsChange();
    }

    protected applyFilters(filters: Partial<ReportListFilters>) {
        const { text, statuses, isCreatedByOfficer, isEmergencyReport, createdDateFrom, createdDateTo, officerUnits } = filters ?? {};

        const params = {
            text: text ? text : null,
            statuses: statuses?.length ? statuses.join(",") : null,
            isCreatedByOfficer: isCreatedByOfficer ? isCreatedByOfficer : null,
            isEmergencyReport: isEmergencyReport ? isEmergencyReport : null,
            createdDateFrom: createdDateFrom ? this.convertToISOString(createdDateFrom) : null,
            createdDateTo: createdDateTo ? this.convertToISOString(createdDateTo) : null,
            officerUnits: officerUnits?.length ? officerUnits.join(",") : null,
            [PAGE_NUMBER_QUERY_PARAM]: 0,
        };

        this.updateQueryParams(params);
    }

    protected async updateRowStatus(statusChange: ReportStatusChange) {
        await firstValueFrom(this.store.dispatch(new ReportManagementActions.UpdateReportStatus(statusChange)).pipe(untilDestroyed(this)));

        const error = this.store.selectSnapshot(ReportManagementState.reportManagementError);

        if (error) {
            this.toastrService.error(this.translocoService.translate("uavIdAdminLibReportMgmt.reportList.statusUpdateUnknownError"));

            return;
        }

        await this.getReportList();

        this.toastrService.success(this.translocoService.translate("uavIdAdminLibReportMgmt.reportList.statusUpdateSuccessMessage"));
    }

    protected getReportList() {
        const queryParams = this.route.snapshot.queryParams;

        return firstValueFrom(
            this.store.dispatch(new ReportManagementActions.GetReports(InvolvementType.RegionHost, queryParams)).pipe(untilDestroyed(this))
        );
    }

    protected changePage(event: PageEvent) {
        this.updateQueryParams({ [PAGE_NUMBER_QUERY_PARAM]: event?.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event?.pageSize });
    }

    protected sortList(sort: Sort, resetPage = false): void {
        const active = sort.direction ? sort.active : null;
        const direction = sort.direction ? sort.direction : null;

        this.updateQueryParams({ active, direction, ...(resetPage && { [PAGE_NUMBER_QUERY_PARAM]: 0 }) });
    }

    protected openInterventionNoteDialog(data: ReportListItem): void {
        this.interventionNoteDialogService
            .openDialog(data)
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                tap(() => this.getReportList()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected async openAssignOfficerDialog(report: ReportListItem): Promise<void> {
        const dialogAfterSubmit = await this.officerAssignDialogService.openAssignOfficerDialog(report);

        dialogAfterSubmit
            ?.pipe(
                tap(() => this.getReportList()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected async openAssignOfficerUnitDialog(report: ReportListItem) {
        const officerUnits = await this.getOfficerUnits();
        const currentlyAssignedOfficerUnit = officerUnits?.find((unit) => unit.id === report.officerUnit.id);
        const hasExtendedAccess = await firstValueFrom(this.hasExtendedAccess$.pipe(untilDestroyed(this)));
        const confirmationText = this.translocoService.translate(
            hasExtendedAccess
                ? "uavIdAdminLibReportMgmt.reportList.changeOfficerUnitConfirmationTextExtendedAccess"
                : "uavIdAdminLibReportMgmt.reportList.changeOfficerUnitConfirmationText"
        );

        this.dialogService
            .open(AssignOfficerUnitDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("uavIdAdminLibReportMgmt.reportList.changeOfficerUnitConfirmationTitle"),
                    confirmationText,
                    confirmButtonLabel: this.translocoService.translate(
                        "uavIdAdminLibReportMgmt.reportList.changeOfficerUnitConfirmButtonLabel"
                    ),
                    declineButtonLabel: this.translocoService.translate(
                        "uavIdAdminLibReportMgmt.reportList.changeOfficerUnitDeclineButtonLabel"
                    ),
                    officerUnit: currentlyAssignedOfficerUnit,
                    officerUnits,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap((officerUnit) => this.store.dispatch(new ReportManagementActions.UpdateOfficerUnit(report.id, officerUnit))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(ReportManagementState.updateOfficerUnitError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("uavIdAdminLibReportMgmt.reportList.changeOfficerUnitErrorMessage")
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("uavIdAdminLibReportMgmt.reportList.changeOfficerUnitSuccessMessage")
                );
                this.getReportList();
            });
    }

    private async getOfficerUnits(): Promise<OfficerUnitEntity[] | undefined> {
        const officerUnits = this.store.selectSnapshot(OfficerState.officerUnits);

        if (!officerUnits || !officerUnits.length) {
            await firstValueFrom(this.store.dispatch(new OfficerActions.GetAllOfficerUnits()).pipe(untilDestroyed(this)));

            if (this.store.selectSnapshot(OfficerState.getOfficerUnitsError)) {
                this.toastrService.error(this.translocoService.translate("uavIdAdminLibReportMgmt.reportList.getOfficerUnitsErrorMessage"));

                return;
            }

            return this.store.selectSnapshot(OfficerState.officerUnits);
        }

        return officerUnits;
    }

    private updateReportListOnQueryParamsChange(): void {
        this.route.queryParams
            .pipe(
                distinctUntilChanged(),
                tap(() => this.getReportList()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private convertToISOString(value: Date | string): string {
        return value instanceof Date ? value.toISOString() : value;
    }

    private updateQueryParams(queryParams: Params): void {
        this.router
            .navigate(["."], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: "merge",
            })
            .catch(Logger.captureException);
    }
}
