import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UserEntity } from "../../../shared/models/user.models";

interface ReportPreviewOfficerComponentState {
    officer: UserEntity | undefined;
}

@Component({
    selector: "uav-id-shared-lib-report-preview-officer[officer]",
    templateUrl: "./report-preview-officer.component.html",
    styleUrls: ["../report-preview-section.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportPreviewOfficerComponent {
    @Input() public set officer(value: UserEntity | undefined) {
        this.localStore.patchState({ officer: value });
    }

    @Output() protected officerAssign = new EventEmitter<void>();

    protected readonly officer$ = this.localStore.selectByKey("officer");

    constructor(private readonly localStore: LocalComponentStore<ReportPreviewOfficerComponentState>) {
        this.localStore.setState({
            officer: undefined,
        });
    }

    protected displayOfficerFullName({ firstName, lastName }: UserEntity): string {
        return `${firstName} ${lastName}`;
    }
}
