<div class="dialog-header">
    <h2 mat-dialog-title class="title-container">
        <span>{{ data.zone.designator + ", " + data.zone.type }}</span>
        <span class="height">{{ data.zone.heightRange }}</span>
    </h2>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content class="details">
    <dtm-ui-info-message *ngIf="data.hasError; else dataTemplate" type="error">
        {{ "uavIdSharedLibReport.reportGeoZoneDialog.dataError" | transloco }}
    </dtm-ui-info-message>

    <ng-template #dataTemplate>
        <dtm-ui-label-value [label]="'uavIdSharedLibReport.reportGeoZoneDialog.descriptionLabel' | transloco">
            <span
                *ngIf="data.details?.description as description; else emptyValueTemplate"
                [innerHTML]="description | phoneNumbersToClickable | emailToClickable | urlToClickable"
            ></span>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'uavIdSharedLibReport.reportGeoZoneDialog.contactLabel' | transloco">
            <span
                *ngIf="data.details?.contact as contact; else emptyValueTemplate"
                [innerHTML]="contact | phoneNumbersToClickable | emailToClickable | urlToClickable"
            ></span>
        </dtm-ui-label-value>
    </ng-template>

    <ng-template #emptyValueTemplate>
        <span>{{ "uavIdSharedLibReport.reportGeoZoneDialog.noDataMessage" | transloco }}</span>
    </ng-template>
</div>
