import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ReportGeoZoneDetails, ReportGeoZoneInfo } from "../../models/report-management.models";

export interface ReportGeoZoneDialogData {
    zone: ReportGeoZoneInfo;
    details: ReportGeoZoneDetails | undefined;
    hasError: boolean;
}

@Component({
    selector: "uav-id-shared-lib-report-geo-zone-dialog",
    templateUrl: "report-geo-zone-dialog.component.html",
    styleUrls: ["report-geo-zone-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportGeoZoneDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ReportGeoZoneDialogData) {}
}
