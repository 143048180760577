import { ElementRef, InjectionToken } from "@angular/core";
import { LatLngExpression, Map } from "leaflet";

export interface LeafletMapProvider {
    getMap(): Promise<Map>;
    getControlSections(): Promise<ElementRef[]>;
}
export interface LeafletMapConfig {
    defaultPosition: LatLngExpression;
    zoom: {
        min: number;
        max: number;
        initial: number; // NOTE: Should be within range of min & max
        userPosition: number; // NOTE: Should be within range of min & max
    };
}

export const LEAFLET_MAP_CONFIG = new InjectionToken<LeafletMapConfig>("Leaflet Map Config");
export const LEAFLET_MAP_PROVIDER = new InjectionToken<LeafletMapProvider>("Leaflet Map Provider");
