import { PhoneNumber } from "@dtm-frontend/shared/ui";

export enum UserType {
    Admin = "ADMIN",
    Civilian = "CIVILIAN",
    Officer = "OFFICER",
    DutyOfficer = "DUTY_OFFICER",
}

export enum OfficerStatus {
    Active = "ACTIVE",
    Inactive = "INACTIVE",
    Rejected = "REJECTED",
    PendingActivation = "PENDING_ACTIVATION",
}

export interface UserEntity {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    type: UserType;
}

export interface OfficerEntity extends UserEntity {
    officerUnit: OfficerUnitEntity;
    officerStatus: OfficerStatus;
    modifiedOfficerStatusAt: Date;
}

export interface OfficerUnitEntity {
    id: string;
    name: string;
    uniformedServiceId: string;
}

export interface OfficerInstitutionEntity {
    id: string;
    name: string;
    emailDomain: string;
}
