<ng-container
    *ngrxLet="{
        report: report$,
        hasExtendedDetails: hasExtendedDetails$,
        geoZonesLayerConfig: geoZonesLayerConfig$,
        isZonesInfoProcessing: isZonesInfoProcessing$,
        zonesInfo: zonesInfo$,
        zonesError: zonesError$
    } as vm"
>
    <dtm-map-leaflet-map *ngIf="vm.report">
        <uav-id-shared-lib-leaflet-flights-layer
            [flights]="flights$ | ngrxPush"
            [hasExtendedDetails]="vm.hasExtendedDetails"
        ></uav-id-shared-lib-leaflet-flights-layer>

        <uav-id-shared-lib-report-uav-area [position]="vm.report.uav.location" [isDraggable]="false" [isInteractive]="false">
        </uav-id-shared-lib-report-uav-area>

        <dtm-map-leaflet-user-position
            [userPosition]="vm.report.userLocation"
            [isDeviceOrientationEnabled]="false"
            [isGeoLocationEnabled]="false"
        >
            <div dtmMapLeafletTooltipContent class="tooltip-container">
                <div class="tooltip-header">
                    {{ "uavIdSharedLibReport.reportPreviewMap.UserPositionLabel" | transloco }}
                </div>
                <span>{{ vm.report.address }}</span>
            </div>
        </dtm-map-leaflet-user-position>

        <dtm-map-leaflet-map-layer
            *ngIf="vm.hasExtendedDetails && !!vm.geoZonesLayerConfig"
            [layerConfig]="vm.geoZonesLayerConfig | invoke : updateGeoZonesLayerConfigWithReportId : vm.report"
            (pointSelect)="zonesSelect.emit($event)"
        ></dtm-map-leaflet-map-layer>

        <dtm-map-leaflet-remote-id-layer [data]="remoteIds$ | ngrxPush"></dtm-map-leaflet-remote-id-layer>
    </dtm-map-leaflet-map>

    <uav-id-shared-lib-report-preview-zones-info-component
        [isProcessing]="vm.isZonesInfoProcessing"
        [zonesInfo]="vm.zonesInfo"
        [zonesError]="vm.zonesError"
        (zoneDetailsOpen)="zoneDetailsOpen.emit($event)"
    ></uav-id-shared-lib-report-preview-zones-info-component>
</ng-container>
