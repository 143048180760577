import { Injectable } from "@angular/core";
import { DialogService } from "@dtm-frontend/shared/ui";
import { Report, ReportListItem, ReportManagementActions, ReportManagementState } from "@dtm-frontend/uav-identification-shared-lib/report";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { filter, firstValueFrom, switchMap, tap } from "rxjs";
import { OfficerAssignDialogComponent } from "./officer-assign-dialog.component";

@UntilDestroy()
@Injectable()
export class OfficerAssignDialogService {
    constructor(
        private readonly dialogService: DialogService,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {}

    public async openAssignOfficerDialog(report: ReportListItem | Report) {
        let activeOfficers = this.store.selectSnapshot(ReportManagementState.activeOfficers)[report.officerUnit.id];

        if (!activeOfficers) {
            await firstValueFrom(
                this.store.dispatch(new ReportManagementActions.GetActiveOfficers(report.officerUnit.id)).pipe(untilDestroyed(this))
            );

            if (this.store.selectSnapshot(ReportManagementState.hasGetActiveOfficersErrorOccurred)) {
                this.toastrService.error(
                    this.translocoService.translate("uavIdAdminLibReportMgmt.officerAssignDialog.getOfficersErrorList")
                );

                return;
            }

            activeOfficers = this.store.selectSnapshot(ReportManagementState.activeOfficers)[report.officerUnit.id];
        }

        return this.dialogService
            .open(OfficerAssignDialogComponent, {
                data: { assignedOfficer: report.assignedOfficer, officers: activeOfficers },
            })
            .afterClosed()
            .pipe(
                filter((data) => data?.isConfirmed),
                switchMap((data) =>
                    this.store.dispatch(new ReportManagementActions.UpdateAssignedOfficer(report.id, data.assignedOfficer.id))
                ),
                tap(() => {
                    const error = this.store.selectSnapshot(ReportManagementState.updateAssignedOfficerError);

                    if (error) {
                        this.toastrService.error(
                            this.translocoService.translate("uavIdAdminLibReportMgmt.officerAssignDialog.assignErrorMessage")
                        );

                        return;
                    }

                    this.toastrService.success(
                        this.translocoService.translate("uavIdAdminLibReportMgmt.officerAssignDialog.assignSuccessMessage")
                    );
                })
            );
    }
}
