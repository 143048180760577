import { PluginListenerHandle } from "@capacitor/core";

export interface RemoteIdPlugin {
    addListener(eventName: string, listenerFunc: (data: Partial<RemoteId.Data>) => void): Promise<PluginListenerHandle>;
}

export namespace RemoteId {
    export interface Data {
        basic: Basic;
        location: Location;
        operatorId: OperatorId;
        selfId: SelfId;
        systemData: System;
        extraInfo: ExtraInfo;
        messageSource: MessageSource;
    }

    export interface Basic {
        uaType: UaType;
        idType: IdType;
        uasId: string;
    }

    export interface Location {
        direction: number;
        speed: Speed;
        position: Position;
        altitude: Altitude;
        accuracy: Accuracy;
        status: AircraftStatus;
        time: number;
        timeAccuracy: number;
    }

    export interface OperatorId {
        operatorId: string;
    }

    export interface SelfId {
        descriptionType: number;
        operationDescription: string;
    }

    export interface System {
        operatorLocationType: OperatorLocationType;
        classificationType: ClassificationType;
        operatorPosition: Position;
        area: Area;
        aircraft: Aircraft;
        altitudeGeo: number;
    }

    export interface ExtraInfo {
        timestamp: number;
        macAddress: string;
        signalStrength: number;
    }

    export interface Position {
        latitude: number;
        longitude: number;
    }

    interface Speed {
        horizontal: number;
        vertical: number;
    }

    interface Altitude {
        pressure: number;
        geodetic: number;
        height: Height;
    }

    interface Height {
        value: number;
        type: HeightType;
    }

    interface Accuracy {
        horizontal: HorizontalAccuracy;
        vertical: VerticalAccuracy;
        baro: VerticalAccuracy;
        speed: SpeedAccuracy;
    }

    interface Area {
        count: number;
        radius: number;
        ceiling: number;
        floor: number;
    }

    interface Aircraft {
        category: AircraftCategory;
        aircraftClass: AircraftClass;
    }

    /* eslint-disable @typescript-eslint/prefer-enum-initializers */
    export enum AircraftCategory {
        UNDECLARED = "UNDECLARED",
        EU_OPEN = "EU_OPEN",
        EU_SPECIFIC = "EU_SPECIFIC",
        EU_CERTIFIED = "EU_CERTIFIED",
    }

    export enum AircraftClass {
        UNDECLARED = "UNDECLARED",
        EU_CLASS_0 = "EU_CLASS_0",
        EU_CLASS_1 = "EU_CLASS_1",
        EU_CLASS_2 = "EU_CLASS_2",
        EU_CLASS_3 = "EU_CLASS_3",
        EU_CLASS_4 = "EU_CLASS_4",
        EU_CLASS_5 = "EU_CLASS_5",
        EU_CLASS_6 = "EU_CLASS_6",
    }

    export enum AircraftStatus {
        UNDECLARED = "UNDECLARED",
        GROUND = "GROUND",
        AIRBORNE = "AIRBORNE",
        EMERGENCY = "EMERGENCY",
        REMOTE_ID_SYSTEM_FAILURE = "REMOTE_ID_SYSTEM_FAILURE",
    }

    export enum ClassificationType {
        UNDECLARED = "UNDECLARED",
        EU = "EU",
    }

    export enum HeightType {
        TAKEOFF = "TAKEOFF",
        AGL = "AGL",
    }

    export enum HorizontalAccuracy {
        UNKNOWN = "UNKNOWN",
        KILOMETERS_18_52 = "KILOMETERS_18_52",
        KILOMETERS_7_408 = "KILOMETERS_7_408",
        KILOMETERS_3_704 = "KILOMETERS_3_704",
        KILOMETERS_1_852 = "KILOMETERS_1_852",
        METERS_926 = "METERS_926",
        METERS_555_6 = "METERS_555_6",
        METERS_185_2 = "METERS_185_2",
        METERS_92_6 = "METERS_92_6",
        METERS_30 = "METERS_30",
        METERS_10 = "METERS_10",
        METERS_3 = "METERS_3",
        METERS_1 = "METERS_1",
    }

    export enum IdType {
        NONE = "NONE",
        SERIAL_NUMBER = "SERIAL_NUMBER",
        CAA_REGISTRATION_ID = "CAA_REGISTRATION_ID",
        UTM_ASSIGNED_ID = "UTM_ASSIGNED_ID",
        SPECIFIC_SESSION_ID = "SPECIFIC_SESSION_ID",
    }

    export enum MessageSource {
        BLUETOOTH_LEGACY = "BLUETOOTH_LEGACY",
        BLUETOOTH_LONG_RANGE = "BLUETOOTH_LONG_RANGE",
        WIFI_NAN = "WIFI_NAN",
        WIFI_BEACON = "WIFI_BEACON",
        UNKNOWN = "UNKNOWN",
    }

    export enum OperatorLocationType {
        TAKE_OFF = "TAKE_OFF",
        LIVE_GNSS = "LIVE_GNSS",
        FIXED_LOCATION = "FIXED_LOCATION",
        INVALID = "INVALID",
    }

    export enum SpeedAccuracy {
        UNKNOWN = "UNKNOWN",
        METER_PER_SECOND_10 = "METER_PER_SECOND_10",
        METER_PER_SECOND_3 = "METER_PER_SECOND_3",
        METER_PER_SECOND_1 = "METER_PER_SECOND_1",
        METER_PER_SECOND_0_3 = "METER_PER_SECOND_0_3",
    }

    export enum UaType {
        NONE = "NONE",
        AEROPLANE = "AEROPLANE",
        HELICOPTER_OR_MULTIROTOR = "HELICOPTER_OR_MULTIROTOR",
        GYROPLANE = "GYROPLANE",
        HYBRID_LIFT = "HYBRID_LIFT",
        ORNITHOPTER = "ORNITHOPTER",
        GLIDER = "GLIDER",
        KITE = "KITE",
        FREE_BALLOON = "FREE_BALLOON",
        CAPTIVE_BALLOON = "CAPTIVE_BALLOON",
        AIRSHIP = "AIRSHIP",
        FREE_FALL_PARACHUTE = "FREE_FALL_PARACHUTE",
        ROCKET = "ROCKET",
        TETHERED_POWERED_AIRCRAFT = "TETHERED_POWERED_AIRCRAFT",
        GROUND_OBSTACLE = "GROUND_OBSTACLE",
        OTHER = "OTHER",
    }

    export enum VerticalAccuracy {
        UNKNOWN = "UNKNOWN",
        METERS_150 = "METERS_150",
        METERS_45 = "METERS_45",
        METERS_25 = "METERS_25",
        METERS_10 = "METERS_10",
        METERS_3 = "METERS_3",
        METERS_1 = "METERS_1",
    }
}
