<ng-container *ngrxLet="officer$ as officer">
    <div class="header-container">
        <h3>
            <dtm-ui-icon name="sticky-note"></dtm-ui-icon>
            {{ "uavIdSharedLibReport.reportPreviewOfficer.header" | transloco }}
        </h3>
        <button type="button" class="button-secondary" (click)="officerAssign.emit()">
            <dtm-ui-icon name="edit"></dtm-ui-icon>
            {{
                (officer
                    ? "uavIdSharedLibReport.reportPreviewOfficer.editButtonLabel"
                    : "uavIdSharedLibReport.reportPreviewOfficer.addButtonLabel"
                ) | transloco
            }}
        </button>
    </div>

    <div *ngIf="officer; else noAssignedOfficerTemplate" class="split-content-container">
        <div>
            <div class="label">{{ "uavIdSharedLibReport.reportPreviewOfficer.fullNameLabel" | transloco }}</div>
            <div class="value">{{ officer | invoke : displayOfficerFullName }}</div>
        </div>

        <div>
            <div class="label">{{ "uavIdSharedLibReport.reportPreviewOfficer.phoneNumberLabel" | transloco }}</div>
            <div class="value">{{ officer.phoneNumber | formatPhoneNumber }}</div>
        </div>

        <div>
            <div class="label">{{ "uavIdSharedLibReport.reportPreviewOfficer.emailLabel" | transloco }}</div>
            <div class="value">{{ officer.email }}</div>
        </div>
    </div>
</ng-container>

<ng-template #noAssignedOfficerTemplate>
    <span class="value">{{ "uavIdSharedLibReport.reportPreviewOfficer.noAssignedOfficerMessage" | transloco }}</span>
</ng-template>
