<div #mapElement class="map"></div>
<div #topLeftControls class="controls-section" (click)="$event.stopPropagation()" (dblclick)="$event.stopPropagation()">
    <ng-content select="[topLeftControls]"></ng-content>
</div>
<div #topRightControls class="controls-section" (click)="$event.stopPropagation()" (dblclick)="$event.stopPropagation()">
    <ng-content select="[topRightControls]"></ng-content>
</div>
<div
    #bottomRightControls
    class="controls-section controls-bottom-right"
    (click)="$event.stopPropagation()"
    (dblclick)="$event.stopPropagation()"
>
    <div class="default-controls">
        <ng-container *ngIf="userPositionComponent">
            <ng-container *ngTemplateOutlet="userPositionComponent.moveToUserPositionButton"></ng-container>
        </ng-container>

        <ng-content select="[mapControls]"></ng-content>

        <ng-container *ngIf="showZoomButtons">
            <div *ngrxLet="currentZoom$ as zoom" class="zoom-controls">
                <button class="leaflet-button" type="button" [disabled]="zoom >= config.zoom.max" (click)="zoomIn()">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                </button>

                <button class="leaflet-button" type="button" [disabled]="zoom <= config.zoom.min" (click)="zoomOut()">
                    <dtm-ui-icon name="subtract"></dtm-ui-icon>
                </button>
            </div>
        </ng-container>
    </div>

    <ng-content select="[bottomRightControls]"></ng-content>
</div>
