import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, METERS_IN_KILOMETER } from "@dtm-frontend/shared/utils";
import { StatusChange } from "../../../shared/models/status-badge.models";
import { Report } from "../../models/report-management.models";
import { ReportReason, ReportStatus, ReportUavHeightEstimation } from "../../models/report-shared.models";
import { REPORT_STATUS_BADGE_CONFIGURATION } from "../../models/report-status-badge.models";

interface ReportPreviewDetailsComponentState {
    availableUpdateStatuses: ReportStatus[] | undefined;
    canChangeStatus: boolean;
    isStatusUpdateProcessing: boolean;
    report: Report | undefined;
}

@Component({
    selector: "uav-id-shared-lib-report-preview-details[report]",
    templateUrl: "./report-preview-details.component.html",
    styleUrls: ["../report-preview-section.scss", "./report-preview-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportPreviewDetailsComponent {
    @Input() public set report(value: Report | undefined) {
        this.localStore.patchState({ report: value });
    }
    @Input() public set isStatusUpdateProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isStatusUpdateProcessing: !!value });
    }
    @Input() public set canChangeStatus(value: boolean | undefined) {
        this.localStore.patchState({ canChangeStatus: !!value });
    }
    @Input() public set availableUpdateStatuses(value: ReportStatus[] | undefined) {
        this.localStore.patchState({ availableUpdateStatuses: value });
    }

    @Output() protected readonly statusUpdate = new EventEmitter<StatusChange>();

    protected readonly availableUpdateStatuses$ = this.localStore.selectByKey("availableUpdateStatuses");
    protected readonly report$ = this.localStore.selectByKey("report");
    protected readonly isStatusUpdateProcessing$ = this.localStore.selectByKey("isStatusUpdateProcessing");
    protected readonly canChangeStatus$ = this.localStore.selectByKey("canChangeStatus");

    protected readonly REPORT_STATUS_BADGE_CONFIGURATION = REPORT_STATUS_BADGE_CONFIGURATION;
    protected readonly ReportUavHeightEstimation = ReportUavHeightEstimation;
    protected readonly ReportReason = ReportReason;

    constructor(private readonly localStore: LocalComponentStore<ReportPreviewDetailsComponentState>) {
        this.localStore.setState({
            availableUpdateStatuses: undefined,
            canChangeStatus: false,
            isStatusUpdateProcessing: false,
            report: undefined,
        });
    }

    protected getRadiusInKm(radius?: number): string | undefined {
        if (radius === undefined || radius === null) {
            return;
        }

        return `${radius / METERS_IN_KILOMETER}`;
    }
}
