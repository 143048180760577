import { curveMonotoneX, line } from "d3-shape";
import { Handler, LatLng, LeafletEvent, LeafletMouseEvent, Map, Point, Polyline } from "leaflet";

export interface HandDrawOptions {
    strokeWidth: number;
    svgZIndex: number;
}

interface MapInteractionsStates {
    isDraggingEnabled: boolean;
    isTouchZoomEnabled: boolean;
    isDoubleClickZoomEnabled: boolean;
    isScrollWheelZoomEnabled: boolean;
    isBoxZoomEnabled: boolean;
    isKeyboardEnabled: boolean;
    isTapEnabled?: boolean;
}

enum MouseEventButton {
    LeftButton = 0,
    MiddleButton = 1,
    RightButton = 2,
}

export class HandDraw extends Handler {
    private readonly svgCanvasElement = this.map.getContainer().appendChild(this.getDrawingSvgCanvas());
    private readonly drawingCoordinates = new Set<LatLng>();
    private interactionsStates: MapInteractionsStates | undefined;
    private lineIterator: (toPoint: Point) => void = () => {};

    constructor(private readonly map: Map, private readonly options: HandDrawOptions, private readonly document: Document) {
        super(map);
    }

    public addHooks(): void {
        this.map.on("mousedown", this.onMouseDown, this);
        this.map.getContainer().classList.add("leaflet-hand-draw-enabled");
    }

    public removeHooks(): void {
        this.map.off("mousedown", this.onMouseDown, this);
        this.map.off("mousemove", this.onMouseMove, this);
        this.map.off("touchmove", this.onMouseMove, this);
        this.map.off("mouseup", this.onMouseUp, this);
        this.restoreMapInteractions();
        this.map.getContainer().classList.remove("leaflet-hand-draw-enabled");
    }

    private getDrawingSvgCanvas(): SVGSVGElement {
        const svgCanvas = this.document.createElementNS("http://www.w3.org/2000/svg", "svg");

        svgCanvas.classList.add("hand-draw");
        svgCanvas.setAttribute("width", "100%");
        svgCanvas.setAttribute("height", "100%");
        svgCanvas.style.pointerEvents = "none";
        svgCanvas.style.position = "relative";
        svgCanvas.style.zIndex = this.options.svgZIndex.toString();

        return svgCanvas;
    }

    private onMouseDown(event: LeafletEvent): void {
        const mouseDownEvent = event as LeafletMouseEvent;

        switch (mouseDownEvent.originalEvent.button) {
            case MouseEventButton.LeftButton: {
                this.onLeftMouseDown(mouseDownEvent);
                break;
            }
        }
    }

    private onLeftMouseDown(mouseDownEvent: LeafletMouseEvent): void {
        this.drawingCoordinates.clear();
        this.disableMapInteractions();
        // NOTE: Create the line iterator and move it to its first `yield` point, passing in the start point from the mouse down event.
        this.lineIterator = this.createPath(this.map.latLngToContainerPoint(mouseDownEvent.latlng), this.options.strokeWidth);

        this.map.on("mousemove", this.onMouseMove, this);
        this.map.on("touchmove", this.onMouseMove, this);
        this.map.on("mouseup", this.onMouseUp, this);
    }

    private onMouseMove(mouseMoveEvent: LeafletEvent): void {
        // NOTE: Resolve the pixel point to the latitudinal and longitudinal equivalent.
        const point = this.map.mouseEventToContainerPoint((mouseMoveEvent as LeafletMouseEvent).originalEvent);

        // NOTE: Push each lat/lng value into the points set.
        this.drawingCoordinates.add(this.map.containerPointToLatLng(point));

        // NOTE: Invoke the generator by passing in the starting point for the path.
        this.lineIterator(new Point(point.x, point.y));
    }

    private onMouseUp(): void {
        this.restoreMapInteractions();
        // NOTE: Stop listening to the events.
        this.map.off("mouseup", this.onMouseUp, this);
        this.map.off("mousemove", this.onMouseMove, this);
        this.map.off("touchmove", this.onMouseMove, this);

        // NOTE: Clear the SVG canvas.
        this.svgCanvasElement.innerHTML = "";

        if (this.drawingCoordinates.size > 1) {
            const polyline = new Polyline(Array.from(this.drawingCoordinates));

            this.map.fire("CompleteHandDrawing", { propagatedFrom: polyline });
        }
    }

    private createPath(fromPoint: Point, strokeWidth: number): (toPoint: Point) => void {
        let lastPoint = fromPoint;

        const lineFunction = line<Point>()
            .curve(curveMonotoneX)
            .x((point) => point.x)
            .y((point) => point.y);

        return (nextPoint: Point) => {
            const lineData = [lastPoint, nextPoint];
            lastPoint = nextPoint;
            // NOTE: Draw SVG line based on the last movement of the mouse's position.
            const pathElement = this.document.createElementNS("http://www.w3.org/2000/svg", "path");
            pathElement.classList.add("leaflet-line");
            pathElement.setAttribute("d", lineFunction(lineData) ?? "");
            pathElement.setAttribute("fill", "none");
            pathElement.setAttribute("stroke", "black");
            pathElement.setAttribute("stroke-width", strokeWidth.toString());

            this.svgCanvasElement.appendChild(pathElement);
        };
    }

    private disableMapInteractions() {
        this.interactionsStates = {
            isDraggingEnabled: this.map.dragging.enabled(),
            isTouchZoomEnabled: this.map.touchZoom.enabled(),
            isDoubleClickZoomEnabled: this.map.doubleClickZoom.enabled(),
            isScrollWheelZoomEnabled: this.map.scrollWheelZoom.enabled(),
            isBoxZoomEnabled: this.map.boxZoom.enabled(),
            isKeyboardEnabled: this.map.keyboard.enabled(),
            isTapEnabled: this.map.tap && this.map.tap.enabled(),
        };

        this.map.dragging.disable();
        this.map.touchZoom.disable();
        this.map.doubleClickZoom.disable();
        this.map.scrollWheelZoom.disable();
        this.map.boxZoom.disable();
        this.map.keyboard.disable();
        if (this.map.tap) {
            this.map.tap.disable();
        }
    }

    private restoreMapInteractions(): void {
        if (this.interactionsStates?.isDraggingEnabled) {
            this.map.dragging.enable();
        }

        if (this.interactionsStates?.isTouchZoomEnabled) {
            this.map.touchZoom.enable();
        }

        if (this.interactionsStates?.isDoubleClickZoomEnabled) {
            this.map.doubleClickZoom.enable();
        }

        if (this.interactionsStates?.isScrollWheelZoomEnabled) {
            this.map.scrollWheelZoom.enable();
        }

        if (this.interactionsStates?.isBoxZoomEnabled) {
            this.map.boxZoom.enable();
        }

        if (this.interactionsStates?.isKeyboardEnabled) {
            this.map.keyboard.enable();
        }

        if (this.interactionsStates?.isTapEnabled) {
            this.map.tap?.enable();
        }
    }
}
