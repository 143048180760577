<div class="header-container">
    <h3>
        <dtm-ui-icon name="sticky-note"></dtm-ui-icon>
        {{ "uavIdSharedLibReport.reportPreviewInterventionNote.header" | transloco }}
    </h3>
    <button type="button" class="button-secondary" (click)="dialogOpen.emit()">
        <dtm-ui-icon name="edit"></dtm-ui-icon>
        {{ "uavIdSharedLibReport.reportPreviewInterventionNote.editButtonLabel" | transloco }}
    </button>
</div>

<!-- TODO: Replace with appropriate component - UX-75 -->
<div *ngIf="report$ | ngrxPush as report" class="content">{{ report.interventionNote }}</div>
