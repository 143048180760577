import { Injectable, OnDestroy } from "@angular/core";
import { PluginListenerHandle } from "@capacitor/core";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { RemoteId, RemoteIdApi } from "remote-id";
import { BehaviorSubject, Observable } from "rxjs";

export const DIRECTION_ERROR_VALUE = 361;
export const HEIGHT_ERROR_VALUE = -1000;
export const PRESSURE_ALTITUDE_ERROR_VALUE = -1000;
export const GEODETIC_ALTITUDE_ERROR_VALUE = -1000;
export const HORIZONTAL_SPEED_ERROR_VALUE = 255;
export const VERTICAL_SPEED_ERROR_VALUE = 63;
export const POSITION_ERROR_VALUE = 0;

@Injectable()
export class RemoteIdService implements OnDestroy {
    private remoteIdPluginListenerHandle: Promise<PluginListenerHandle> | undefined;
    private readonly currentRemoteIds: Record<string, Partial<RemoteId.Data>> = {};
    private readonly remoteIdData = new BehaviorSubject<Partial<RemoteId.Data> | null>(null);

    constructor() {
        this.remoteIdPluginListenerHandle = RemoteIdApi.addListener("remoteId", (data: Partial<RemoteId.Data>) => {
            const filteredData = this.filterOutEmptyProperties(data);

            this.remoteIdData.next(filteredData);
            this.updateCurrentRemoteIds(filteredData);
        });
    }

    public ngOnDestroy() {
        this.remoteIdPluginListenerHandle?.then((listenerHandle) => listenerHandle.remove());
    }

    public retrieveRemoteIdDataStream(): Observable<Partial<RemoteId.Data> | null> {
        return this.remoteIdData.asObservable();
    }

    public getCurrentRemoteIds(): Array<Partial<RemoteId.Data>> {
        return Object.keys(this.currentRemoteIds).map((key) => this.currentRemoteIds[key]);
    }

    private updateCurrentRemoteIds(data: Partial<RemoteId.Data>): void {
        const macAddress = data.extraInfo?.macAddress;
        if (!macAddress) {
            return;
        }

        this.currentRemoteIds[macAddress] = { ...this.currentRemoteIds[macAddress], ...data };
    }

    private filterOutEmptyProperties(data: Partial<RemoteId.Data>): Partial<RemoteId.Data> {
        return Object.fromEntries(Object.entries(data).filter(([_, value]) => !FunctionUtils.isNullOrUndefined(value)));
    }
}
