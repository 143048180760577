<ng-container *ngIf="data$ | ngrxPush as data">
    <ng-container *ngIf="isMobile$ | ngrxPush; else desktopContainerTemplate">
        <dtm-ui-accordion>
            <dtm-ui-expandable-panel isExpanded>
                <div headerSlot [ngTemplateOutlet]="basicDataHeaderTemplate"></div>
                <section class="section">
                    <ng-container [ngTemplateOutlet]="basicDataContentTemplate"></ng-container>
                </section>
            </dtm-ui-expandable-panel>

            <dtm-ui-expandable-panel>
                <div headerSlot [ngTemplateOutlet]="locationHeaderTemplate"></div>
                <section class="section">
                    <ng-container [ngTemplateOutlet]="locationContentTemplate"></ng-container>
                </section>
            </dtm-ui-expandable-panel>

            <dtm-ui-expandable-panel>
                <div headerSlot [ngTemplateOutlet]="operatorDataHeaderTemplate"></div>
                <section class="section">
                    <ng-container [ngTemplateOutlet]="operatorDataContentTemplate"></ng-container>
                </section>
            </dtm-ui-expandable-panel>

            <dtm-ui-expandable-panel>
                <div headerSlot [ngTemplateOutlet]="extraInfoHeaderTemplate"></div>
                <section class="section">
                    <ng-container [ngTemplateOutlet]="extraInfoContentTemplate"></ng-container>
                </section>
            </dtm-ui-expandable-panel>

            <dtm-ui-expandable-panel>
                <div headerSlot [ngTemplateOutlet]="uavCommunicationHeaderTemplate"></div>
                <section class="section">
                    <ng-container [ngTemplateOutlet]="uavCommunicationContentTemplate"></ng-container>
                </section>
            </dtm-ui-expandable-panel>
        </dtm-ui-accordion>
    </ng-container>

    <ng-template #desktopContainerTemplate>
        <div class="container">
            <section class="section">
                <div class="section-header"><ng-container [ngTemplateOutlet]="basicDataHeaderTemplate"></ng-container></div>
                <ng-container [ngTemplateOutlet]="basicDataContentTemplate"></ng-container>
            </section>

            <section class="section">
                <div class="section-header"><ng-container [ngTemplateOutlet]="locationHeaderTemplate"></ng-container></div>
                <ng-container [ngTemplateOutlet]="locationContentTemplate"></ng-container>
            </section>

            <section *ngIf="data.operatorId?.operatorId" class="section">
                <div class="section-header"><ng-container [ngTemplateOutlet]="operatorDataHeaderTemplate"></ng-container></div>
                <ng-container [ngTemplateOutlet]="operatorDataContentTemplate"></ng-container>
            </section>

            <section *ngIf="data.selfId?.operationDescription" class="section">
                <div class="section-header"><ng-container [ngTemplateOutlet]="extraInfoHeaderTemplate"></ng-container></div>
                <ng-container [ngTemplateOutlet]="extraInfoContentTemplate"></ng-container>
            </section>

            <section class="section">
                <div class="section-header"><ng-container [ngTemplateOutlet]="uavCommunicationHeaderTemplate"></ng-container></div>
                <ng-container [ngTemplateOutlet]="uavCommunicationContentTemplate"></ng-container>
            </section>
        </div>
    </ng-template>

    <ng-template #basicDataHeaderTemplate>{{ "dtmMapLeafletRemoteId.details.basicDataHeader" | transloco }}</ng-template>
    <ng-template #basicDataContentTemplate>
        <ng-container
            *ngIf="data.basic?.idType && data.basic?.uasId"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.uavIdTypeValueLabel' | transloco : { value: data.basic?.idType },
                value: data.basic?.uasId
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.basic?.uaType as uaType"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.uavTypeLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.uavTypeValueLabel' | transloco : { value: uaType }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.systemData?.aircraft?.category as category"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.aircraftCategoryLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.aircraftCategoryValueLabel' | transloco : { value: category }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.systemData?.aircraft?.aircraftClass as aircraftClass"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.aircraftClassLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.aircraftClassValueLabel' | transloco : { value: aircraftClass }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.systemData?.area?.count | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.uavNumberLabel' | transloco,
                value: data.systemData?.area?.count
            }"
        ></ng-container>

        <ng-container
            *ngIf="(data.systemData?.area?.count ?? 0) > 1 && data.systemData?.area?.radius | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.areaRadiusLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.areaRadiusValueLabel' | transloco : { value: data.systemData?.area?.radius }
            }"
        ></ng-container>

        <ng-container
            *ngIf="(data.systemData?.area?.count ?? 0) > 1"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.areaHeightRangeLabel' | transloco,
                value:
                    data.systemData?.area?.floor == null ||
                    data.systemData?.area?.floor === HEIGHT_ERROR_VALUE ||
                    data.systemData?.area?.ceiling == null ||
                    data.systemData?.area?.ceiling === HEIGHT_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : ('dtmMapLeafletRemoteId.details.areaHeightRangeValueLabel'
                          | transloco
                              : {
                                    floor: data.systemData?.area?.floor,
                                    ceiling: data.systemData?.area?.ceiling
                                })
            }"
        ></ng-container>
    </ng-template>

    <ng-template #locationHeaderTemplate>{{ "dtmMapLeafletRemoteId.details.locationHeader" | transloco }}</ng-template>
    <ng-template #locationContentTemplate>
        <ng-container
            *ngIf="data.location?.position as position"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.uavCoordinatesLabel' | transloco,
                value:
                    position.longitude === POSITION_ERROR_VALUE && position.latitude === POSITION_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : (position | invoke : convertRemoteIdPositionToDmsString),
                accuracy:
                    'dtmMapLeafletRemoteId.details.horizontalAccuracyValueLabel'
                    | transloco : { value: data.location?.accuracy?.horizontal }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.location?.speed?.horizontal | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.horizontalSpeedLabel' | transloco,
                value:
                    data.location?.speed?.horizontal === HORIZONTAL_SPEED_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : ('dtmMapLeafletRemoteId.details.speedValueLabel' | transloco : { value: data.location?.speed?.horizontal }),
                accuracy: 'dtmMapLeafletRemoteId.details.speedAccuracyValueLabel' | transloco : { value: data.location?.accuracy?.speed }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.location?.speed?.vertical | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.verticalSpeedLabel' | transloco,
                value:
                    data.location?.speed?.vertical === VERTICAL_SPEED_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : ('dtmMapLeafletRemoteId.details.speedValueLabel' | transloco : { value: data.location?.speed?.vertical }),
                accuracy: 'dtmMapLeafletRemoteId.details.speedAccuracyValueLabel' | transloco : { value: data.location?.accuracy?.speed }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.location?.direction | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.directionLabel' | transloco,
                value:
                    data.location?.direction === DIRECTION_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : data.location?.direction
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.location?.altitude?.height as height"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.heightLabel' | transloco : { value: height.type },
                value:
                    height.value === HEIGHT_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : ('dtmMapLeafletRemoteId.details.heightValueLabel' | transloco : { value: height.value }),
                accuracy:
                    'dtmMapLeafletRemoteId.details.heightAccuracyValueLabel' | transloco : { value: data.location?.accuracy?.vertical }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.location?.altitude?.pressure | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.pressureAltitudeLabel' | transloco,
                value:
                    data.location?.altitude?.pressure === PRESSURE_ALTITUDE_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : ('dtmMapLeafletRemoteId.details.pressureAltitudeValueLabel'
                          | transloco : { value: data.location?.altitude?.pressure }),
                accuracy:
                    'dtmMapLeafletRemoteId.details.pressureAltitudeAccuracyValueLabel'
                    | transloco : { value: data.location?.accuracy?.baro }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.location?.altitude?.geodetic | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.geodeticAltitudeLabel' | transloco,
                value:
                    data.location?.altitude?.geodetic === GEODETIC_ALTITUDE_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : ('dtmMapLeafletRemoteId.details.geodeticAltitudeValueLabel' | transloco : { value: data.location?.altitude?.geodetic }),
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.location?.status as status"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.aircraftStatusLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.aircraftStatusValueLabel' | transloco : { value: status }
            }"
        ></ng-container>
    </ng-template>

    <ng-template #operatorDataHeaderTemplate>{{ "dtmMapLeafletRemoteId.details.operatorDataHeader" | transloco }}</ng-template>
    <ng-template #operatorDataContentTemplate>
        <div *ngIf="data.operatorId?.operatorId as operatorId" class="property">
            <span class="label">{{ "dtmMapLeafletRemoteId.details.operatorIdLabel" | transloco }}</span>
            <a class="link" target="_blank" [href]="operatorId | invoke : getOperatorProfileUrl : uavAppUrls">{{ operatorId }}</a>
        </div>

        <ng-container
            *ngIf="data.systemData?.operatorPosition as position"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.operatorLocationTypeLabel' | transloco: {value: data.systemData?.operatorLocationType},
                value: position.longitude === POSITION_ERROR_VALUE && position.latitude === POSITION_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : (position | invoke : convertRemoteIdPositionToDmsString),
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.systemData?.altitudeGeo | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.operatorGeoAltitudeLabel' | transloco,
                value:
                    data.systemData?.altitudeGeo === HEIGHT_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : ('dtmMapLeafletRemoteId.details.heightValueLabel' | transloco : { value: data.systemData?.altitudeGeo }),
            }"
        ></ng-container>
    </ng-template>

    <ng-template #extraInfoHeaderTemplate>{{ "dtmMapLeafletRemoteId.details.extraInfoHeader" | transloco }}</ng-template>
    <ng-template #extraInfoContentTemplate>
        <ng-container
            *ngIf="data.selfId?.operationDescription as operationDescription"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.operationDescriptionLabel' | transloco,
                value: operationDescription
            }"
        ></ng-container>
    </ng-template>

    <ng-template #uavCommunicationHeaderTemplate>{{ "dtmMapLeafletRemoteId.details.uavCommunicationHeader" | transloco }}</ng-template>
    <ng-template #uavCommunicationContentTemplate>
        <ng-container
            *ngIf="data.extraInfo?.signalStrength | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.signalStrengthLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.signalStrengthValueLabel' | transloco : { value: data.extraInfo?.signalStrength }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.extraInfo?.macAddress as macAddress"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.macAddressLabel' | transloco,
                value: macAddress
            }"
        ></ng-container>

        <ng-container
            *ngIf="timestamp$ | ngrxPush as timestamp"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.timeStampLabel' | transloco,
                value: timestamp | localizeDate : { dateStyle: 'medium', timeStyle: 'medium' }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.messageSource as messageSource"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.sourceLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.sourceValueLabel' | transloco : { value: messageSource }
            }"
        ></ng-container>
    </ng-template>
</ng-container>

<ng-template #propertyTemplate let-label="label" let-value="value" let-accuracy="accuracy">
    <div class="property">
        <span class="label">{{ label }}</span>
        <span class="value">
            {{ value }}
            <span *ngIf="accuracy">
                {{ "dtmMapLeafletRemoteId.details.accuracyAdditionalInfoLabel" | transloco : { value: accuracy } }}
            </span>
        </span>
    </div>
</ng-template>
