import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { HttpParams } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { RemoteId } from "remote-id";
import { Flight } from "../../../flight/models/flight.models";
import { Report, ReportGeoZoneInfo, ReportManagementError } from "../../models/report-management.models";

interface ReportPreviewMapComponentState {
    report: Report | undefined;
    flights: Flight[];
    hasExtendedDetails: boolean;
    remoteIds: Array<Partial<RemoteId.Data>>;
    zonesLayerConfig: LeafletMapLayerConfig | undefined;
    isZonesInfoProcessing: boolean;
    zonesInfo: ReportGeoZoneInfo[];
    zonesError: ReportManagementError | undefined;
}

@Component({
    selector: "uav-id-shared-lib-report-preview-map[report][flights]",
    templateUrl: "./report-preview-map.component.html",
    styleUrls: ["./report-preview-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportPreviewMapComponent {
    @Input() public set report(value: Report | undefined) {
        this.localStore.patchState({ report: value });
    }
    @Input() public set flights(value: Flight[] | undefined) {
        this.localStore.patchState({ flights: value ?? [] });
    }
    @Input() public set hasExtendedDetails(value: boolean | undefined) {
        this.localStore.patchState({ hasExtendedDetails: coerceBooleanProperty(value) });
    }
    @Input() public set remoteIds(value: Array<Partial<RemoteId.Data>> | undefined) {
        this.localStore.patchState({ remoteIds: value });
    }
    @Input() public set zonesLayerConfig(value: LeafletMapLayerConfig | undefined) {
        this.localStore.patchState({ zonesLayerConfig: value });
    }
    @Input() public set isZonesInfoProcessing(value: BooleanInput) {
        this.localStore.patchState({ isZonesInfoProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set zonesInfo(value: ReportGeoZoneInfo[] | undefined) {
        this.localStore.patchState({ zonesInfo: value ?? [] });
    }
    @Input() public set zonesError(value: ReportManagementError | undefined) {
        this.localStore.patchState({ zonesError: value });
    }

    @Output() protected readonly zonesSelect = new EventEmitter<HttpParams>();
    @Output() protected readonly zoneDetailsOpen = new EventEmitter<ReportGeoZoneInfo>();

    protected readonly flights$ = this.localStore.selectByKey("flights");
    protected readonly report$ = this.localStore.selectByKey("report");
    protected readonly hasExtendedDetails$ = this.localStore.selectByKey("hasExtendedDetails");
    protected readonly remoteIds$ = this.localStore.selectByKey("remoteIds");
    protected readonly geoZonesLayerConfig$ = this.localStore.selectByKey("zonesLayerConfig");
    protected readonly isZonesInfoProcessing$ = this.localStore.selectByKey("isZonesInfoProcessing");
    protected readonly zonesInfo$ = this.localStore.selectByKey("zonesInfo");
    protected readonly zonesError$ = this.localStore.selectByKey("zonesError");

    constructor(private readonly localStore: LocalComponentStore<ReportPreviewMapComponentState>) {
        this.localStore.setState({
            report: undefined,
            flights: [],
            hasExtendedDetails: false,
            remoteIds: [],
            zonesLayerConfig: undefined,
            isZonesInfoProcessing: false,
            zonesInfo: [],
            zonesError: undefined,
        });
    }

    protected updateGeoZonesLayerConfigWithReportId(config: LeafletMapLayerConfig, report: Report): LeafletMapLayerConfig {
        config.options = { ...config.options, CQL_FILTER: `report_id='${report.id}'` };

        return config;
    }
}
