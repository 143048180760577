<ng-container *ngIf="data$ | ngrxPush as data">
    <ng-container *ngIf="isMobile$ | ngrxPush; else desktopContainerTemplate">
        <dtm-ui-accordion>
            <dtm-ui-expandable-panel isExpanded>
                <div headerSlot [ngTemplateOutlet]="operatorSectionHeaderTemplate"></div>
                <section class="section">
                    <ng-container [ngTemplateOutlet]="operatorSectionContentTemplate"></ng-container>
                </section>
            </dtm-ui-expandable-panel>

            <dtm-ui-expandable-panel>
                <div headerSlot [ngTemplateOutlet]="uavSectionHeaderTemplate"></div>
                <section class="section">
                    <ng-container [ngTemplateOutlet]="uavSectionContentTemplate"></ng-container>
                </section>
            </dtm-ui-expandable-panel>
        </dtm-ui-accordion>
    </ng-container>
    <ng-template #desktopContainerTemplate>
        <div class="container">
            <section class="section">
                <div class="section-header"><ng-container [ngTemplateOutlet]="operatorSectionHeaderTemplate"></ng-container></div>
                <ng-container [ngTemplateOutlet]="operatorSectionContentTemplate"></ng-container>
            </section>

            <section class="section">
                <div class="section-header"><ng-container [ngTemplateOutlet]="uavSectionHeaderTemplate"></ng-container></div>
                <ng-container [ngTemplateOutlet]="uavSectionContentTemplate"></ng-container>
            </section>
        </div>
    </ng-template>

    <ng-template #operatorSectionHeaderTemplate>{{ "dtmMapLeafletRemoteId.details.operatorSectionHeader" | transloco }}</ng-template>
    <ng-template #operatorSectionContentTemplate>
        <div *ngIf="data.operatorId?.operatorId as operatorId" class="property">
            <span class="label">{{ "dtmMapLeafletRemoteId.details.operatorIdLabel" | transloco }}</span>
            <a class="link" target="_blank" [href]="operatorId | invoke : getOperatorProfileUrl : uavAppUrls">{{ operatorId }}</a>
        </div>

        <ng-container
            *ngIf="data.systemData?.operatorPosition as position"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.operatorLocationTypeLabel' | transloco: {value: data.systemData?.operatorLocationType},
                value: position.longitude === POSITION_ERROR_VALUE && position.latitude === POSITION_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : (position | invoke : convertRemoteIdPositionToDmsString),
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.systemData?.altitudeGeo | invoke : isNotNullOrUndefined"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.operatorGeoAltitudeLabel' | transloco,
                value:
                    data.systemData?.altitudeGeo === HEIGHT_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : ('dtmMapLeafletRemoteId.details.heightValueLabel' | transloco : { value: data.systemData?.altitudeGeo }),
            }"
        ></ng-container>
    </ng-template>

    <ng-template #uavSectionHeaderTemplate>{{ "dtmMapLeafletRemoteId.details.uavSectionHeader" | transloco }}</ng-template>
    <ng-template #uavSectionContentTemplate>
        <ng-container
            *ngIf="data.basic?.idType && data.basic?.uasId"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.uavIdTypeValueLabel' | transloco : { value: data.basic?.idType },
                value: data.basic?.uasId
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.location?.position as position"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.uavCoordinatesLabel' | transloco,
                value:
                    position.longitude === POSITION_ERROR_VALUE && position.latitude === POSITION_ERROR_VALUE
                        ? ('dtmMapLeafletRemoteId.details.noDataLabel' | transloco)
                        : (position | invoke : convertRemoteIdPositionToDmsString),
                accuracy:
                    'dtmMapLeafletRemoteId.details.horizontalAccuracyValueLabel'
                    | transloco : { value: data.location?.accuracy?.horizontal }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.basic?.uaType as uaType"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.uavTypeLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.uavTypeValueLabel' | transloco : { value: uaType }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.systemData?.aircraft?.category as category"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.aircraftCategoryLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.aircraftCategoryValueLabel' | transloco : { value: category }
            }"
        ></ng-container>

        <ng-container
            *ngIf="data.systemData?.aircraft?.aircraftClass as aircraftClass"
            [ngTemplateOutlet]="propertyTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMapLeafletRemoteId.details.aircraftClassLabel' | transloco,
                value: 'dtmMapLeafletRemoteId.details.aircraftClassValueLabel' | transloco : { value: aircraftClass }
            }"
        ></ng-container>
    </ng-template>
</ng-container>

<ng-template #propertyTemplate let-label="label" let-value="value" let-accuracy="accuracy">
    <div class="property">
        <span class="label">{{ label }}</span>
        <span class="value">
            {{ value }}
            <span *ngIf="accuracy">
                {{ "dtmMapLeafletRemoteId.details.accuracyAdditionalInfoLabel" | transloco : { value: accuracy } }}
            </span>
        </span>
    </div>
</ng-template>
