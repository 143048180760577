import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ComponentRef, Directive, Inject, Input, ViewContainerRef } from "@angular/core";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { BaseIconOptions, Circle, FeatureGroup, GeoJSON, Icon, LatLng, Layer, Map, Marker, PathOptions, PopupOptions } from "leaflet";
import { Flight, FlightType } from "../../../flight/models/flight.models";
import { LeafletFlightsLayerDetailsComponent } from "./leaflet-flights-layer-details/leaflet-flights-layer-details.component";

interface LeafletFlightsLayerDirectiveState {
    hasExtendedDetails: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare function require(moduleName: string): any; // TODO: DTM-5250

const LEAFLET_MARKER_CLUSTER = require("leaflet.markercluster");

const PATH_COLOR = "#1477d5"; // NOTE: #1477d5 - $color-secondary-700
const CHECKIN_CIRCLE_DEFAULT_RADIUS = 500;
const CHECKIN_PATH_OPTIONS: PathOptions = {
    color: PATH_COLOR,
    weight: 1,
    dashArray: "5",
    fillColor: PATH_COLOR,
    fillOpacity: 0.3,
};
const MISSION_PATH_OPTIONS: PathOptions = {
    color: PATH_COLOR,
    opacity: 1,
    weight: 1,
    dashArray: "1",
    fillColor: PATH_COLOR,
    fillOpacity: 0.3,
    fillRule: "nonzero",
};
const POPUP_WIDTH = 300;
const POPUP_OPTIONS: PopupOptions = {
    closeButton: false,
    className: "info-popup",
    minWidth: POPUP_WIDTH,
    maxWidth: POPUP_WIDTH,
};
const CHECKIN_ICON_OPTIONS: BaseIconOptions = {
    iconUrl: "assets/images/map/checkin.svg",
    /* eslint-disable no-magic-numbers */
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    popupAnchor: [0, -10],
    /* eslint-enable */
};

@Directive({ selector: "uav-id-shared-lib-leaflet-flights-layer[flights]", providers: [LocalComponentStore] })
export class LeafletFlightsLayerDirective {
    @Input() public areDetailsShown = true;

    @Input() public set hasExtendedDetails(value: BooleanInput) {
        const hasExtendedDetails = coerceBooleanProperty(value);

        this.localStore.patchState({ hasExtendedDetails });
        this.detailComponents.forEach((component) => (component.instance.hasExtendedDetails = hasExtendedDetails));
    }

    @Input() public set flights(value: Flight[] | undefined) {
        if (value === undefined) {
            return;
        }

        this.displayFlights(value);
    }

    private map!: Map;
    private flightsLayer: GeoJSON | undefined;
    private markersLayer: FeatureGroup | undefined;
    private detailComponents: ComponentRef<LeafletFlightsLayerDetailsComponent>[] = [];

    constructor(
        private readonly localStore: LocalComponentStore<LeafletFlightsLayerDirectiveState>,
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        this.localStore.setState({ hasExtendedDetails: false });
    }

    private async displayFlights(flights: Flight[]) {
        if (!this.map) {
            this.map = await this.mapProvider.getMap();
        }

        this.removeObsoleteLayers();

        this.flightsLayer = new GeoJSON(this.getFlightsFeatureCollection(flights), {
            pointToLayer: (geoJsonPoint, latlng: LatLng): Layer =>
                new Circle(latlng, { radius: geoJsonPoint.properties.flight.routeVolume.radius ?? CHECKIN_CIRCLE_DEFAULT_RADIUS }),
            style: (feature) => {
                switch (feature?.properties.flight.type) {
                    case FlightType.Checkin:
                        return CHECKIN_PATH_OPTIONS;
                    case FlightType.Mission:
                        return MISSION_PATH_OPTIONS;
                    default:
                        return {};
                }
            },
            onEachFeature: (feature, layer: Layer) => {
                const flight: Flight = feature.properties.flight;
                if (!this.areDetailsShown || flight.type === FlightType.Checkin) {
                    return;
                }

                const detailsComponent = this.createDetailsComponent(flight);
                layer.bindPopup(detailsComponent?.location.nativeElement, POPUP_OPTIONS);
            },
        });

        this.markersLayer = new LEAFLET_MARKER_CLUSTER.MarkerClusterGroup() as FeatureGroup;
        flights.forEach((flight) => {
            if (flight.type === FlightType.Checkin) {
                const [lng, lat] = (flight.routeVolume.flightArea as GeoJSON.Point).coordinates;
                const marker = new Marker([lat, lng], {
                    icon: new Icon(CHECKIN_ICON_OPTIONS),
                });

                if (this.areDetailsShown) {
                    const detailsComponent = this.createDetailsComponent(flight);
                    marker.bindPopup(detailsComponent?.location.nativeElement, POPUP_OPTIONS);
                }

                this.markersLayer?.addLayer(marker);
            }
        });

        this.map.addLayer(this.flightsLayer);
        this.map.addLayer(this.markersLayer);
    }

    private removeObsoleteLayers(): void {
        if (this.flightsLayer) {
            this.map.removeLayer(this.flightsLayer);
            this.detailComponents.forEach((component) => component.destroy());
            this.detailComponents = [];
        }

        if (this.markersLayer) {
            this.map.removeLayer(this.markersLayer);
        }
    }

    private getFlightsFeatureCollection(flights: Flight[]): GeoJSON.FeatureCollection {
        return {
            type: "FeatureCollection",
            features: flights.map((flight) => ({
                type: "Feature",
                geometry: flight.routeVolume.flightArea,
                properties: {
                    flight,
                },
            })),
        };
    }

    private createDetailsComponent(flight: Flight): ComponentRef<LeafletFlightsLayerDetailsComponent> {
        const detailsComponent = this.viewContainerRef.createComponent(LeafletFlightsLayerDetailsComponent);
        detailsComponent.instance.flight = flight;
        detailsComponent.instance.hasExtendedDetails = this.localStore.selectSnapshotByKey("hasExtendedDetails");

        this.detailComponents.push(detailsComponent);

        return detailsComponent;
    }
}
