import { StatusBadgeConfiguration, StatusBadgeTheme } from "../../shared/models/status-badge.models";
import { ReportStatus } from "./report-shared.models";

export const REPORT_STATUS_BADGE_CONFIGURATION: StatusBadgeConfiguration = {
    translationKey: "uavIdSharedLibReport.reportStatusLabel",
    badges: [
        {
            status: ReportStatus.New,
            icon: "star",
            theme: StatusBadgeTheme.Success,
        },
        {
            status: ReportStatus.UnderAnalysis,
            icon: "map-pin-time",
            theme: StatusBadgeTheme.Secondary,
        },
        {
            status: ReportStatus.Completed,
            icon: "checkbox-circle",
            theme: StatusBadgeTheme.Disabled,
        },
        {
            status: ReportStatus.Rejected,
            icon: "close-circle-fill",
            theme: StatusBadgeTheme.Error,
        },
        {
            status: ReportStatus.Unconfirmed,
            icon: "close-circle",
            theme: StatusBadgeTheme.Error,
        },
        {
            status: ReportStatus.FurtherInvestigationRequired,
            icon: "draft",
            theme: StatusBadgeTheme.Warning,
        },
    ],
};
