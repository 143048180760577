import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ReportingUser } from "../../models/report-management.models";

interface ReportPreviewUserComponentState {
    user: ReportingUser | undefined;
}

@Component({
    selector: "uav-id-shared-lib-report-preview-user[user]",
    templateUrl: "./report-preview-user.component.html",
    styleUrls: ["../report-preview-section.scss", "./report-preview-user.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportPreviewUserComponent {
    @Input() public set user(value: ReportingUser | undefined) {
        this.localStore.patchState({ user: value });
    }

    protected readonly user$ = this.localStore.selectByKey("user");

    constructor(private readonly localStore: LocalComponentStore<ReportPreviewUserComponentState>) {
        this.localStore.setState({
            user: undefined,
        });
    }
}
