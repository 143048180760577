import { IconName } from "@dtm-frontend/shared/ui";

export enum StatusBadgeTheme {
    Disabled = "disabled",
    Error = "error",
    Secondary = "secondary",
    Success = "success",
    Warning = "warning",
}

export interface StatusBadge {
    status: string;
    theme: StatusBadgeTheme;
    icon: IconName;
}

export interface StatusBadgeConfiguration {
    translationKey: string;
    badges: StatusBadge[];
}

export interface StatusChange {
    newStatus: string;
    previousStatus: string;
}
