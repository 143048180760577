import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Sort } from "@angular/material/sort";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { ReportListFilters, ReportStatus } from "@dtm-frontend/uav-identification-shared-lib/report";
import { OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, debounceTime, distinctUntilChanged, map, tap } from "rxjs";

interface ReportListFiltersComponentState {
    appliedFiltersCount: number | undefined;
    initialFilters: ReportListFilters | undefined;
    statuses: ReportStatus[];
    officerUnits: OfficerUnitEntity[];
    hasExtendedAccess: boolean;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "text",
        filterLabel: "uavIdAdminLibReportMgmt.reportListFilters.textSearchLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: "statuses",
        filterLabel: "uavIdAdminLibReportMgmt.reportListFilters.reportStatusLabel",
        filterValueLabel: "uavIdAdminLibReportMgmt.reportListFilters.reportStatusValueLabel",
    },
    {
        key: "createdDateFrom",
        filterLabel: "uavIdAdminLibReportMgmt.reportListFilters.createdDateFromLabel",
        filterValueLabel: "uavIdAdminLibReportMgmt.reportListFilters.createdAtValueLabel",
        type: FilterType.Date,
    },
    {
        key: "createdDateTo",
        filterLabel: "uavIdAdminLibReportMgmt.reportListFilters.createdDateToLabel",
        filterValueLabel: "uavIdAdminLibReportMgmt.reportListFilters.createdAtValueLabel",
        type: FilterType.Date,
    },
    {
        key: "isCreatedByOfficer",
        filterLabel: "uavIdAdminLibReportMgmt.reportListFilters.isCreatedByOfficerLabel",
        filterValueLabel: "uavIdAdminLibReportMgmt.reportListFilters.toggleFilterValueLabel",
    },
    {
        key: "isEmergencyReport",
        filterLabel: "uavIdAdminLibReportMgmt.reportListFilters.isEmergencyReportLabel",
        filterValueLabel: "uavIdAdminLibReportMgmt.reportListFilters.toggleFilterValueLabel",
    },
    {
        key: "officerUnits",
        filterLabel: "uavIdAdminLibReportMgmt.reportListFilters.officerUnitLabel",
    },
];
const DEBOUNCE_TIME = 300;
const SORTING_OPTIONS = [
    {
        active: "number",
        direction: "asc",
        translateKey: "uavIdAdminLibReportMgmt.reportListFilters.sortNumberLabel",
        isExtendedAccessRequired: false,
    },
    {
        active: "number",
        direction: "desc",
        translateKey: "uavIdAdminLibReportMgmt.reportListFilters.sortNumberLabel",
        isExtendedAccessRequired: false,
    },
    {
        active: "reportedAt",
        direction: "asc",
        translateKey: "uavIdAdminLibReportMgmt.reportListFilters.sortReportedAtLabel",
        isExtendedAccessRequired: false,
    },
    {
        active: "reportedAt",
        direction: "desc",
        translateKey: "uavIdAdminLibReportMgmt.reportListFilters.sortReportedAtLabel",
        isExtendedAccessRequired: false,
    },
    {
        active: "officerUnit",
        direction: "asc",
        translateKey: "uavIdAdminLibReportMgmt.reportListFilters.sortOfficerUnitLabel",
        isExtendedAccessRequired: true,
    },
    {
        active: "officerUnit",
        direction: "desc",
        translateKey: "uavIdAdminLibReportMgmt.reportListFilters.sortOfficerUnitLabel",
        isExtendedAccessRequired: true,
    },
];

@UntilDestroy()
@Component({
    selector: "uav-id-admin-lib-report-list-filters[statuses]",
    templateUrl: "./report-list-filters.component.html",
    styleUrls: ["./report-list-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportListFiltersComponent implements OnInit {
    @Input() public set initialFilters(value: ReportListFilters | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }
    @Input() public set statuses(value: ReportStatus[] | undefined) {
        this.localStore.patchState({ statuses: value ?? [] });
    }
    @Input() public set sort(value: Sort | undefined) {
        this.sortControl.setValue(value);
    }
    @Input() public set officerUnits(value: OfficerUnitEntity[] | undefined) {
        this.localStore.patchState({ officerUnits: value });
    }
    @Input() public set hasExtendedAccess(value: boolean | undefined) {
        this.localStore.patchState({ hasExtendedAccess: coerceBooleanProperty(value) });

        if (value) {
            this.officerUnitsControl.enable();

            return;
        }

        this.officerUnitsControl.disable();
    }

    @Output() protected readonly sortChange = new EventEmitter<Sort>();
    @Output() protected readonly filtersChange = new EventEmitter<Partial<ReportListFilters>>();

    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly FILTERS_MAP = FILTERS_MAP;

    protected readonly officerUnits$ = this.localStore.selectByKey("officerUnits").pipe(RxjsUtils.filterFalsy());
    protected readonly appliedFiltersCount$ = this.localStore.selectByKey("appliedFiltersCount");
    protected readonly statuses$ = this.localStore.selectByKey("statuses");
    protected readonly areInitialFiltersProvided$ = this.localStore.selectByKey("initialFilters").pipe(
        RxjsUtils.filterFalsy(),
        combineLatestWith(this.officerUnits$),
        tap(([initialFilters, officerUnits]) => this.setInitialFilters(initialFilters, officerUnits)),
        map(
            ([initialFilters, _]) =>
                !!initialFilters && Object.values(initialFilters).some((filter) => !!filter && (!Array.isArray(filter) || filter.length))
        )
    );
    protected readonly sortingOptions$ = this.localStore
        .selectByKey("hasExtendedAccess")
        .pipe(
            map((hasExtendedAccess) =>
                hasExtendedAccess ? SORTING_OPTIONS : SORTING_OPTIONS.filter((option) => !option.isExtendedAccessRequired)
            )
        );

    protected readonly sortControl = new FormControl<Sort | null | undefined>(null);

    protected readonly textControl = new FormControl<string | null>(null);
    protected readonly statusesControl = new FormControl<ReportStatus[] | null>(null);
    protected readonly createdDateFromControl = new FormControl<Date | null>(null);
    protected readonly createdDateToControl = new FormControl<Date | null>(null);
    protected readonly isCreatedByOfficerControl = new FormControl<boolean | null>(null);
    protected readonly isEmergencyReportControl = new FormControl<boolean | null>(null);
    protected readonly officerUnitsControl = new FormControl<string[] | null>({ value: null, disabled: true });

    protected readonly filtersFormGroup = new FormGroup({
        text: this.textControl,
        statuses: this.statusesControl,
        createdDateFrom: this.createdDateFromControl,
        createdDateTo: this.createdDateToControl,
        isCreatedByOfficer: this.isCreatedByOfficerControl,
        isEmergencyReport: this.isEmergencyReportControl,
        officerUnits: this.officerUnitsControl,
    });

    constructor(
        private readonly localStore: LocalComponentStore<ReportListFiltersComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            appliedFiltersCount: undefined,
            initialFilters: undefined,
            statuses: [],
            officerUnits: [],
            hasExtendedAccess: false,
        });
    }

    public ngOnInit() {
        this.watchFormValueChanges();
    }

    protected clearFilters(): void {
        this.filtersFormGroup.reset({ statuses: [] });
    }

    protected compareSelectOptions(left: Sort | null, right: Sort | null): boolean {
        return left?.active === right?.active && left?.direction === right?.direction;
    }

    private watchFormValueChanges(): void {
        this.filtersFormGroup.valueChanges
            .pipe(
                debounceTime(DEBOUNCE_TIME),
                distinctUntilChanged(),
                tap((value) => {
                    this.updateAppliedFiltersCount();

                    if (this.filtersFormGroup.invalid) {
                        return;
                    }

                    const officerUnitsIds = this.localStore
                        .selectSnapshotByKey("officerUnits")
                        ?.filter((unit) => value?.officerUnits?.includes(unit.name))
                        .map((unit) => unit.id);

                    this.filtersChange.emit({ ...value, officerUnits: officerUnitsIds });
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private updateAppliedFiltersCount(): void {
        if (this.filtersFormGroup.invalid) {
            return;
        }

        const values = Object.values(this.filtersFormGroup.value);
        const appliedFiltersCount = values.flat().filter(Boolean).length;

        this.localStore.patchState({ appliedFiltersCount });
    }

    private setInitialFilters(filters: ReportListFilters, officerUnits: OfficerUnitEntity[]): void {
        const selectedOfficerUnits = officerUnits
            .filter((unit) => filters.officerUnits?.includes(unit.id.toString()))
            .map((unit) => unit.name);

        this.filtersFormGroup.setValue(
            {
                ...filters,
                isCreatedByOfficer: !!filters.isCreatedByOfficer,
                isEmergencyReport: !!filters.isEmergencyReport,
                officerUnits: selectedOfficerUnits,
            },
            { emitEvent: false }
        );
        this.updateAppliedFiltersCount();
    }
}
