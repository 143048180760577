import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Observable, combineLatestWith, map } from "rxjs";
import { StatusBadge, StatusBadgeConfiguration } from "../../models/status-badge.models";

interface StatusBadgeComponentState {
    configuration: StatusBadgeConfiguration | undefined;
    status: string | undefined;
}

@Component({
    selector: "uav-id-shared-lib-status-badge[configuration][status]",
    templateUrl: "./status-badge.component.html",
    styleUrls: ["./status-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StatusBadgeComponent {
    @Input() public set configuration(value: StatusBadgeConfiguration | undefined) {
        this.localStore.patchState({ configuration: value });
    }
    @Input() public set status(value: string | undefined) {
        this.localStore.patchState({ status: value });
    }

    public readonly currentStatusCfg$: Observable<StatusBadge | undefined> = this.localStore.selectByKey("status").pipe(
        combineLatestWith(this.localStore.selectByKey("configuration")),
        map(([status, configuration]) => configuration?.badges?.find((badge: StatusBadge) => badge.status === status))
    );
    public readonly translationKey$: Observable<string | undefined> = this.localStore
        .selectByKey("configuration")
        .pipe(map((configuration) => configuration?.translationKey));

    constructor(private readonly localStore: LocalComponentStore<StatusBadgeComponentState>) {
        this.localStore.setState({
            configuration: undefined,
            status: undefined,
        });
    }
}
