import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Sort } from "@angular/material/sort";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { OfficerStatus, OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, debounceTime, distinctUntilChanged, map, tap } from "rxjs";
import { OfficerListFilters } from "../../models/officer.models";

interface OfficerListFiltersComponentState {
    appliedFiltersCount: number | undefined;
    initialFilters: OfficerListFilters | undefined;
    statuses: OfficerStatus[];
    officerUnits: OfficerUnitEntity[];
    hasExtendedAccess: boolean;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "lastName",
        filterLabel: "uavIdAdminLibOfficer.officerListFilters.lastNameLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: "statuses",
        filterLabel: "uavIdAdminLibOfficer.officerListFilters.statusLabel",
        filterValueLabel: "uavIdAdminLibOfficer.officerStatusLabel",
    },
    {
        key: "officerUnits",
        filterLabel: "uavIdAdminLibOfficer.officerListFilters.officerUnitLabel",
    },
    {
        key: "areDutyOfficersOnly",
        filterLabel: "uavIdAdminLibOfficer.officerListFilters.areDutyOfficersOnlyLabel",
        filterValueLabel: "uavIdAdminLibOfficer.officerListFilters.areDutyOfficersOnlyValueLabel",
    },
];
const FILTERS_UPDATE_DEBOUNCE_TIME = 300;

@UntilDestroy()
@Component({
    selector: "uav-id-admin-lib-officer-list-filters[statuses][officerUnits]",
    templateUrl: "./officer-list-filters.component.html",
    styleUrls: ["./officer-list-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OfficerListFiltersComponent implements OnInit {
    @Input() public set initialFilters(value: OfficerListFilters | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }
    @Input() public set statuses(value: OfficerStatus[] | undefined) {
        this.localStore.patchState({ statuses: value });
    }
    @Input() public set officerUnits(value: OfficerUnitEntity[] | undefined) {
        this.localStore.patchState({ officerUnits: value });
    }
    @Input() public set hasExtendedAccess(value: boolean | undefined) {
        this.localStore.patchState({ hasExtendedAccess: coerceBooleanProperty(value) });
        const relatedControls = [this.officerUnitsControl, this.areDutyOfficersOnlyControl];

        if (value) {
            relatedControls.forEach((control) => control.enable());

            return;
        }

        relatedControls.forEach((control) => control.disable());
    }
    @Input() public set sort(value: Sort | undefined) {
        this.sortControl.setValue(value ?? null);
    }

    @Output() protected readonly filtersChange = new EventEmitter<Partial<OfficerListFilters>>();
    @Output() protected readonly sortChange = new EventEmitter<Sort>();

    protected readonly FILTERS_MAP = FILTERS_MAP;
    private readonly SORTING_OPTIONS = [
        {
            active: "lastName",
            direction: "asc",
            translateKey: "uavIdAdminLibOfficer.officerListFilters.sortLastNameLabel",
            isExtendedAccessRequired: false,
        },
        {
            active: "lastName",
            direction: "desc",
            translateKey: "uavIdAdminLibOfficer.officerListFilters.sortLastNameLabel",
            isExtendedAccessRequired: false,
        },
        {
            active: "officerUnit",
            direction: "asc",
            translateKey: "uavIdAdminLibOfficer.officerListFilters.sortOfficerUnitLabel",
            isExtendedAccessRequired: true,
        },
        {
            active: "officerUnit",
            direction: "desc",
            translateKey: "uavIdAdminLibOfficer.officerListFilters.sortOfficerUnitLabel",
            isExtendedAccessRequired: true,
        },
    ];

    protected readonly appliedFiltersCount$ = this.localStore.selectByKey("appliedFiltersCount");
    protected readonly statuses$ = this.localStore.selectByKey("statuses");
    protected readonly officerUnits$ = this.localStore.selectByKey("officerUnits").pipe(RxjsUtils.filterFalsy());
    protected readonly areInitialFiltersProvided$ = this.localStore.selectByKey("initialFilters").pipe(
        RxjsUtils.filterFalsy(),
        combineLatestWith(this.officerUnits$),
        tap(([initialFilters, officerUnits]) => this.setInitialFilters(initialFilters, officerUnits)),
        map(
            ([initialFilters, _]) => !!initialFilters.lastName || !!initialFilters.statuses?.length || !!initialFilters.officerUnits?.length
        )
    );
    protected readonly sortingOptions$ = this.localStore
        .selectByKey("hasExtendedAccess")
        .pipe(
            map((hasExtendedAccess) =>
                hasExtendedAccess ? this.SORTING_OPTIONS : this.SORTING_OPTIONS.filter((option) => !option.isExtendedAccessRequired)
            )
        );

    protected readonly sortControl = new FormControl<Sort | null>(null);

    protected readonly lastNameControl = new FormControl<string | null>(null);
    protected readonly statusesControl = new FormControl<OfficerStatus[] | null>(null);
    protected readonly officerUnitsControl = new FormControl<string[] | null>({ value: null, disabled: true });
    protected readonly areDutyOfficersOnlyControl = new FormControl<boolean | null>({ value: null, disabled: true });

    protected readonly filtersFormGroup = new FormGroup({
        lastName: this.lastNameControl,
        statuses: this.statusesControl,
        officerUnits: this.officerUnitsControl,
        areDutyOfficersOnly: this.areDutyOfficersOnlyControl,
    });

    constructor(private readonly localStore: LocalComponentStore<OfficerListFiltersComponentState>) {
        this.localStore.setState({
            appliedFiltersCount: undefined,
            initialFilters: undefined,
            statuses: [],
            officerUnits: [],
            hasExtendedAccess: false,
        });
    }

    public ngOnInit() {
        this.watchFormValueChanges();
    }

    protected clearFilters(): void {
        this.filtersFormGroup.reset();
    }

    protected compareSelectOptions(left: Sort | null, right: Sort | null): boolean {
        return left?.active === right?.active && left?.direction === right?.direction;
    }

    private watchFormValueChanges(): void {
        this.filtersFormGroup.valueChanges
            .pipe(
                debounceTime(FILTERS_UPDATE_DEBOUNCE_TIME),
                distinctUntilChanged(),
                tap((value) => {
                    this.updateAppliedFiltersCount();

                    if (this.filtersFormGroup.invalid) {
                        return;
                    }

                    const officerUnitsIds = this.localStore
                        .selectSnapshotByKey("officerUnits")
                        .filter((unit) => value?.officerUnits?.includes(unit.name))
                        .map((unit) => unit.id);

                    this.filtersChange.emit({ ...value, officerUnits: officerUnitsIds });
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private updateAppliedFiltersCount(): void {
        if (this.filtersFormGroup.invalid) {
            return;
        }

        const values = Object.values(this.filtersFormGroup.value);
        const appliedFiltersCount = values.flat().filter(Boolean).length;

        this.localStore.patchState({ appliedFiltersCount });
    }

    private setInitialFilters(initialFilters: OfficerListFilters, officerUnits: OfficerUnitEntity[]): void {
        const selectedOfficerUnits = officerUnits
            .filter((unit) => initialFilters.officerUnits?.includes(unit.id.toString()))
            .map((unit) => unit.name);

        this.filtersFormGroup.setValue({ ...initialFilters, officerUnits: selectedOfficerUnits }, { emitEvent: false });
        this.updateAppliedFiltersCount();
    }
}
