import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { NotAuthorizedComponent } from "@dtm-frontend/shared/ui";
import { OfficerRoutingModule } from "@dtm-frontend/uav-identification-admin-lib/officer";
import { ReportManagementRoutingModule } from "@dtm-frontend/uav-identification-admin-lib/report-management";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";

const routes: Routes = [
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    { path: "**", redirectTo: "/reports" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), ReportManagementRoutingModule, OfficerRoutingModule],
    exports: [RouterModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: [UavIdRole.Admin, UavIdRole.DutyOfficer],
        },
    ],
})
export class AppRoutingModule {}
