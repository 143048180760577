import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ReportGeoZoneInfo, ReportManagementError } from "../../models/report-management.models";

interface ReportPreviewZonesInfoComponentState {
    isProcessing: boolean;
    zonesInfo: ReportGeoZoneInfo[];
    zonesError: ReportManagementError | undefined;
}

@Component({
    selector: "uav-id-shared-lib-report-preview-zones-info-component",
    templateUrl: "report-preview-zones-info.component.html",
    styleUrls: ["report-preview-zones-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportPreviewZonesInfoComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set zonesInfo(value: ReportGeoZoneInfo[] | undefined) {
        this.localStore.patchState({ zonesInfo: value ?? [] });
    }

    @Input() public set zonesError(value: ReportManagementError | undefined) {
        this.localStore.patchState({ zonesError: value });
    }

    @Output() protected readonly zoneDetailsOpen = new EventEmitter<ReportGeoZoneInfo>();

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly zonesInfo$ = this.localStore.selectByKey("zonesInfo");
    protected readonly zonesError$ = this.localStore.selectByKey("zonesError");
    protected readonly isMobile$ = this.deviceSizeService.getSizeObservable(DeviceSize.Smartphone);

    constructor(
        private readonly deviceSizeService: DeviceSizeService,
        private readonly localStore: LocalComponentStore<ReportPreviewZonesInfoComponentState>
    ) {
        this.localStore.setState({ isProcessing: false, zonesInfo: [], zonesError: undefined });
    }

    protected closePanel(): void {
        this.localStore.patchState({ zonesInfo: [] });
    }
}
