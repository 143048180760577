<ng-template #moveToUserPositionButton>
    <div
        *ngrxLet="userPosition$; let userPosition"
        #tooltip="matTooltip"
        [matTooltip]="
            (!userPosition ? 'dtmMapLeaflet.tooltips.userLocation.disabled' : 'dtmMapLeaflet.tooltips.userLocation.active') | transloco
        "
        (click)="!userPosition ? tooltip?.toggle() : tooltip?.hide()"
    >
        <button class="leaflet-button" type="button" [disabled]="!userPosition" (click)="moveToPosition(userPosition)">
            <dtm-ui-icon name="focus-2"></dtm-ui-icon>
        </button>
    </div>
</ng-template>

<dtm-map-leaflet-marker
    [position]="userPosition$ | ngrxPush"
    [rotation]="userDeviceOrientation$ | ngrxPush"
    [isDraggable]="arePositionChangesEnabled"
    [isInteractive]="isMarkerInteractive"
    [iconOptions]="iconOptions"
    (initialize)="onMarkerInitialized($event)"
    (positionUpdate)="onMarkerPositionUpdated($event)"
></dtm-map-leaflet-marker>
