import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { AbstractControl, UntypedFormControl, ValidationErrors, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { InterventionNoteDialogData } from "../../models/report-shared.models";
import { REPORT_STATUS_BADGE_CONFIGURATION } from "../../models/report-status-badge.models";

interface InterventionNoteDialogComponentState {
    isNoteProcessing: boolean;
}

const NOTE_MAX_LENGTH = 2000;
const MIN_ROWS = 20;

@UntilDestroy()
@Component({
    selector: "uav-id-shared-lib-intervention-note-dialog",
    templateUrl: "./intervention-note-dialog.component.html",
    styleUrls: ["./intervention-note-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InterventionNoteDialogComponent {
    public set isNoteProcessing(value: boolean) {
        this.localStore.patchState({ isNoteProcessing: value });
    }

    private readonly submitNote = new Subject<string>();
    public readonly submitNote$ = this.submitNote.asObservable();

    protected readonly NOTE_MAX_LENGTH = NOTE_MAX_LENGTH;
    protected readonly REPORT_STATUS_BADGE_CONFIGURATION = REPORT_STATUS_BADGE_CONFIGURATION;

    protected readonly isNoteProcessing$ = this.localStore.selectByKey("isNoteProcessing");

    protected readonly noteControl = new UntypedFormControl(this.data.interventionNote, [
        this.onlyWhitespacesNotAllowed,
        Validators.maxLength(NOTE_MAX_LENGTH),
    ]);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: InterventionNoteDialogData,
        private readonly dialog: MatDialogRef<InterventionNoteDialogComponent>,
        private readonly localStore: LocalComponentStore<InterventionNoteDialogComponentState>
    ) {
        this.localStore.setState({
            isNoteProcessing: false,
        });

        this.data = { ...this.data, minNumberOfRows: this.data.minNumberOfRows ?? MIN_ROWS };
    }

    protected closeDialog(isUpdated = false): void {
        this.dialog.close(isUpdated);
    }

    protected submit() {
        if (this.noteControl.invalid) {
            return;
        }

        this.submitNote.next(this.noteControl.value);
    }

    private onlyWhitespacesNotAllowed(control: AbstractControl): ValidationErrors | null {
        const value = control?.value;
        const isInvalid = value?.length > 0 && value?.trim().length === 0;

        return isInvalid ? { onlyWhitespacesNotAllowed: true } : null;
    }
}
