import {
    CircleMarker,
    CircleMarkerOptions,
    FeatureGroup,
    Handler,
    LatLng,
    LeafletKeyboardEvent,
    LeafletMouseEvent,
    Map,
    Polyline,
    PolylineOptions,
    TooltipOptions,
} from "leaflet";

type LeafletRulerTooltipTextProvider = (sectionDistance: number, totalDistance: number) => string;

export interface LeafletRulerOptions {
    circleMarkerOptions: CircleMarkerOptions;
    polylineOptions: PolylineOptions;
    tooltipOptions: TooltipOptions;
}

const defaultLeafletRulerOptions: LeafletRulerOptions = {
    circleMarkerOptions: {
        color: "#8d99b1", // $color-gray-200
        radius: 2,
    },
    polylineOptions: {
        color: "#8d99b1", // $color-gray-200
        dashArray: "1,6",
    },
    tooltipOptions: {
        permanent: true,
        interactive: true,
        direction: "center",
        className: "ruler-tooltip",
    },
};

export class LeafletRuler extends Handler {
    private readonly tempPolylineLayer = new FeatureGroup().addTo(this.map);
    private rulerPolylineLayer = new FeatureGroup();
    private lastClickedPointCoordinates: LatLng | undefined;
    private clickedPathPoints: LatLng[] = [];
    private totalDistance = 0;

    constructor(
        private readonly map: Map,
        private readonly tooltipTextProvider: LeafletRulerTooltipTextProvider,
        private readonly options: LeafletRulerOptions = defaultLeafletRulerOptions
    ) {
        super(map);
    }

    public addHooks(): void {
        this.map.on("click", this.onClick, this);
        this.map.on("dblclick", this.closePath, this);
        this.map.on("keydown", this.onKeydown, this);

        this.totalDistance = 0;
        this.map.doubleClickZoom.disable();
        this.map.getContainer().classList.add("leaflet-ruler-enabled");
    }

    public removeHooks(): void {
        this.map.off("click", this.onClick, this);
        this.map.off("mousemove", this.onMove, this);
        this.map.off("dblclick", this.closePath, this);
        this.map.off("keydown", this.onKeydown, this);

        this.map.doubleClickZoom.enable();
        this.map.getContainer().classList.remove("leaflet-ruler-enabled");
    }

    private onClick(clickEvent: LeafletMouseEvent): void {
        // NOTE: Ignore clicks on same spot
        if (clickEvent.latlng.equals(this.clickedPathPoints[this.clickedPathPoints.length - 1])) {
            return;
        }

        const isFirstClick = !this.clickedPathPoints.length;
        if (isFirstClick) {
            this.rulerPolylineLayer = new FeatureGroup().addTo(this.map);
            this.rulerPolylineLayer.on("contextmenu", this.removeSelectedLayer, this);
            this.map.on("mousemove", this.onMove, this);
        }

        this.lastClickedPointCoordinates = clickEvent.latlng;
        this.clickedPathPoints.push(this.lastClickedPointCoordinates);

        new CircleMarker(this.lastClickedPointCoordinates, this.options.circleMarkerOptions).addTo(this.rulerPolylineLayer);

        if (this.clickedPathPoints.length > 1) {
            // eslint-disable-next-line no-magic-numbers
            const [penultimatePathPoint, lastPathPoint] = this.clickedPathPoints.slice(-2);

            const distance = this.calculateDistance(penultimatePathPoint, lastPathPoint);
            this.totalDistance += distance;

            const tooltipTotalDistance = this.clickedPathPoints.length > 1 ? this.totalDistance : distance;

            new Polyline([penultimatePathPoint, lastPathPoint], this.options.polylineOptions)
                .addTo(this.rulerPolylineLayer)
                .bindTooltip(this.tooltipTextProvider(distance, tooltipTotalDistance), this.options.tooltipOptions)
                .openTooltip();
        }
    }

    private onMove(moveEvent: LeafletMouseEvent): void {
        if (!this.lastClickedPointCoordinates) {
            return;
        }

        this.tempPolylineLayer.clearLayers();

        const distance = this.calculateDistance(this.lastClickedPointCoordinates, moveEvent.latlng);
        const tooltipTotalDistance = this.clickedPathPoints.length > 1 ? this.totalDistance + distance : distance;

        new Polyline([this.lastClickedPointCoordinates, moveEvent.latlng], this.options.polylineOptions)
            .addTo(this.tempPolylineLayer)
            .bindTooltip(this.tooltipTextProvider(distance, tooltipTotalDistance), this.options.tooltipOptions)
            .openTooltip();
    }

    private closePath(): void {
        this.tempPolylineLayer.clearLayers();
        this.clickedPathPoints = [];
        this.totalDistance = 0;
        this.map.off("mousemove", this.onMove, this);
    }

    private onKeydown(event: LeafletKeyboardEvent): void {
        if (event.originalEvent.key === "Escape") {
            this.closePath();
        }
    }

    private removeSelectedLayer(event: LeafletMouseEvent): void {
        this.closePath();
        this.map.removeLayer(event.target);
    }

    private calculateDistance(startPointCoordinates: LatLng, endPointCoordinates: LatLng): number {
        const startLatitude = startPointCoordinates.lat;
        const startLongitude = startPointCoordinates.lng;
        const endLatitude = endPointCoordinates.lat;
        const endLongitude = endPointCoordinates.lng;
        const toRadian = Math.PI / 180; // eslint-disable-line no-magic-numbers
        const averageEarthRadius = 6371;

        // NOTE: calculation based on Haversine formula
        const deltaLatitude = (endLatitude - startLatitude) * toRadian;
        const deltaLongitude = (endLongitude - startLongitude) * toRadian;
        const archaversine =
            Math.pow(Math.sin(deltaLatitude / 2), 2) +
            Math.cos(startLatitude * toRadian) * Math.cos(endLatitude * toRadian) * Math.pow(Math.sin(deltaLongitude / 2), 2);
        const arcsin = Math.atan2(Math.sqrt(archaversine), Math.sqrt(1 - archaversine));

        return 2 * averageEarthRadius * arcsin;
    }
}
