<ng-container *ngrxLet="configuration$ as configuration">
    <ng-container *ngrxLet="statuses$ as statuses">
        <ng-container *ngIf="status$ | ngrxPush as currentStatus">
            <uav-id-shared-lib-status-badge
                *ngIf="statuses?.length; else plainStatusBadgeTemplate"
                [configuration]="configuration"
                [status]="currentStatus"
                [matMenuTriggerFor]="statusChangeMenu"
                [matMenuTriggerData]="{ $implicit: currentStatus }"
            ></uav-id-shared-lib-status-badge>

            <mat-menu #statusChangeMenu="matMenu">
                <ng-template matMenuContent let-currentStatus>
                    <ng-container *ngFor="let status of statuses">
                        <button
                            *ngIf="currentStatus !== status"
                            type="button"
                            mat-menu-item
                            class="badge-button"
                            (click)="statusChange.emit({ newStatus: status, previousStatus: currentStatus })"
                        >
                            <uav-id-shared-lib-status-badge
                                class="menu-items"
                                [configuration]="configuration"
                                [status]="status"
                            ></uav-id-shared-lib-status-badge>
                        </button>
                    </ng-container>
                </ng-template>
            </mat-menu>

            <ng-template #plainStatusBadgeTemplate>
                <uav-id-shared-lib-status-badge
                    class="plain-badge"
                    [configuration]="configuration"
                    [status]="currentStatus"
                ></uav-id-shared-lib-status-badge>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-container>
