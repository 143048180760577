import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatestWith, map } from "rxjs";
import { StatusBadge, StatusBadgeConfiguration, StatusChange } from "../../models/status-badge.models";

interface StatusBadgeMenuComponentState {
    configuration: StatusBadgeConfiguration | undefined;
    availableStatuses: string[] | undefined;
    status: string | undefined;
}

@Component({
    selector: "uav-id-shared-lib-status-badge-menu[configuration][status]",
    templateUrl: "./status-badge-menu.component.html",
    styleUrls: ["./status-badge-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StatusBadgeMenuComponent {
    @Input() public set configuration(value: StatusBadgeConfiguration | undefined) {
        this.localStore.patchState({ configuration: value });
    }
    @Input() public set status(value: string | undefined) {
        this.localStore.patchState({ status: value });
    }
    @Input() public set availableStatuses(value: string[] | undefined) {
        this.localStore.patchState({ availableStatuses: value });
    }

    @Output() protected statusChange = new EventEmitter<StatusChange>();

    protected readonly configuration$ = this.localStore.selectByKey("configuration");
    protected readonly status$ = this.localStore.selectByKey("status");
    protected readonly statuses$ = this.localStore.selectByKey("configuration").pipe(
        combineLatestWith(this.localStore.selectByKey("availableStatuses")),
        map(([configuration, availableStatuses]) =>
            availableStatuses ? availableStatuses : configuration?.badges.map((badge: StatusBadge) => badge?.status)
        )
    );

    constructor(private readonly localStore: LocalComponentStore<StatusBadgeMenuComponentState>) {
        this.localStore.setState({
            configuration: undefined,
            availableStatuses: undefined,
            status: undefined,
        });
    }
}
