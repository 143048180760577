import { Directive } from "@angular/core";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RemoteIdService } from "../../services/remote-id.service";
import { LeafletRemoteIdLayerDirective } from "./leaflet-remote-id-layer.directive";

@UntilDestroy()
@Directive({ selector: "dtm-map-leaflet-remote-id-layer[dtmMapLeafletRemoteIdData]" })
export class LeafletRemoteIdDataDirective {
    constructor(private readonly remoteIdLayerDirective: LeafletRemoteIdLayerDirective, private readonly remoteIdService: RemoteIdService) {
        this.remoteIdService
            .retrieveRemoteIdDataStream()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((data) => {
                if (!this.remoteIdLayerDirective) {
                    return;
                }

                this.remoteIdLayerDirective.data = [data];
            });
    }
}
