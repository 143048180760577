import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    OfficerEntity,
    OfficerStatus,
    OfficerUnitEntity,
    StatusChange,
    UserType,
} from "@dtm-frontend/uav-identification-shared-lib/shared";
import { HashMap, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AssignOfficerUnitDialogComponent } from "../../../shared/components/assign-officer-unit-dialog/assign-officer-unit-dialog.component";
import { OFFICER_BADGE_CONFIGURATION } from "../../models/officer-badge.models";
import { OfficerListItem, OfficerStatusChange, OfficerUnitChange } from "../../models/officer.models";

interface OfficerListComponentState {
    officers: OfficerListItem[];
    officersPage: Page | undefined;
    officerUnits: OfficerUnitEntity[];
    hasGetListError: boolean;
    isProcessing: boolean;
    sort: Sort | undefined;
    displayedTableColumns: Array<keyof OfficerListItem | "actions">;
    hasExtendedAccess: boolean;
}

const STATUS_CHANGE_MESSAGES = {
    [OfficerStatus.PendingActivation]: undefined,
    [OfficerStatus.Active]: {
        title: "uavIdAdminLibOfficer.officerList.activateOfficerTitle",
        confirmation: "uavIdAdminLibOfficer.officerList.genericConfirmationMessage",
        confirmButtonLabel: "uavIdAdminLibOfficer.officerList.activateOfficerConfirmButtonLabel",
        declineButtonLabel: "uavIdAdminLibOfficer.officerList.declineButtonLabel",
        theme: ButtonTheme.Primary,
    },
    [OfficerStatus.Inactive]: {
        title: "uavIdAdminLibOfficer.officerList.deactivateOrRejectOfficerTitle",
        confirmation: "uavIdAdminLibOfficer.officerList.deactivateOrRejectOfficerConfirmationMessage",
        confirmButtonLabel: "uavIdAdminLibOfficer.officerList.deactivateOrRejectOfficerConfirmButtonLabel",
        declineButtonLabel: "uavIdAdminLibOfficer.officerList.declineButtonLabel",
        theme: ButtonTheme.Warn,
    },
    [OfficerStatus.Rejected]: {
        title: "uavIdAdminLibOfficer.officerList.deactivateOrRejectOfficerTitle",
        confirmation: "uavIdAdminLibOfficer.officerList.deactivateOrRejectOfficerConfirmationMessage",
        confirmButtonLabel: "uavIdAdminLibOfficer.officerList.deactivateOrRejectOfficerConfirmButtonLabel",
        declineButtonLabel: "uavIdAdminLibOfficer.officerList.declineButtonLabel",
        theme: ButtonTheme.Warn,
    },
};

@UntilDestroy()
@Component({
    selector: "uav-id-admin-lib-officer-list[officers][officersPage][officerUnits]",
    templateUrl: "./officer-list.component.html",
    styleUrls: ["./officer-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OfficerListComponent implements AfterViewInit {
    @ViewChild(MatSort) private matSort!: MatSort;

    @Input() public set officers(value: OfficerListItem[] | undefined) {
        this.localStore.patchState({ officers: value ?? [] });
    }
    @Input() public set officersPage(value: Page | undefined) {
        this.localStore.patchState({ officersPage: value });
    }
    @Input() public set officerUnits(value: OfficerUnitEntity[] | undefined) {
        this.localStore.patchState({ officerUnits: value ?? [] });
    }
    @Input() public set hasGetListError(value: boolean | undefined) {
        this.localStore.patchState({ hasGetListError: !!value });
    }
    @Input() public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }
    @Input() public set sort(value: Sort | undefined) {
        this.localStore.patchState({ sort: value });

        if (value && this.matSort) {
            this.matSort.active = value.active;
            this.matSort.direction = value.direction;
        }
    }
    @Input() public set hasExtendedAccess(value: boolean | undefined) {
        value = coerceBooleanProperty(value);

        this.localStore.patchState({
            hasExtendedAccess: value,
            displayedTableColumns: value ? this.extendedAccessDisplayedColumns : this.defaultDisplayedColumns,
        });
    }

    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();
    @Output() protected readonly sortChange = new EventEmitter<Sort>();
    @Output() protected readonly pageReload = new EventEmitter();
    @Output() private readonly statusChange = new EventEmitter<OfficerStatusChange>();
    @Output() private readonly officerPromotion = new EventEmitter<OfficerEntity>();
    @Output() private readonly officerDemotion = new EventEmitter<OfficerEntity>();
    @Output() private readonly officerUnitChange = new EventEmitter<OfficerUnitChange>();

    protected readonly officers$ = this.localStore.selectByKey("officers");
    protected readonly officersPage$ = this.localStore.selectByKey("officersPage");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasGetListError$ = this.localStore.selectByKey("hasGetListError");
    protected readonly displayedTableColumns$ = this.localStore.selectByKey("displayedTableColumns");
    protected readonly hasExtendedAccess$ = this.localStore.selectByKey("hasExtendedAccess");

    protected readonly UserType = UserType;
    protected readonly OfficerStatus = OfficerStatus;
    protected readonly OFFICER_BADGE_CONFIGURATION = OFFICER_BADGE_CONFIGURATION;

    private readonly defaultDisplayedColumns: Array<keyof OfficerListItem> = ["status", "firstName", "lastName", "email", "phoneNumber"];
    private readonly extendedAccessDisplayedColumns: Array<keyof OfficerListItem | "actions"> = [
        ...this.defaultDisplayedColumns,
        "officerUnit",
        "actions",
    ];

    constructor(
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<OfficerListComponentState>,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            officers: [],
            officersPage: undefined,
            officerUnits: [],
            hasGetListError: false,
            isProcessing: false,
            sort: undefined,
            displayedTableColumns: this.defaultDisplayedColumns,
            hasExtendedAccess: false,
        });
    }

    public ngAfterViewInit() {
        this.setInitialSort();
    }

    protected getAvailableStatuses(status: OfficerStatus): OfficerStatus[] {
        switch (status) {
            case OfficerStatus.PendingActivation:
                return [OfficerStatus.Active, OfficerStatus.Rejected];
            case OfficerStatus.Active:
                return [OfficerStatus.Inactive];
            case OfficerStatus.Inactive:
                return [];
            case OfficerStatus.Rejected:
                return [];
        }
    }

    protected openStatusChangeDialog(statusChange: StatusChange, officer: OfficerListItem): void {
        const isActivatingOfficerAndHasExtendedAccess =
            statusChange.newStatus === OfficerStatus.Active && this.localStore.selectSnapshotByKey("hasExtendedAccess");

        if (isActivatingOfficerAndHasExtendedAccess) {
            this.activateAndAssignUnitToOfficer(statusChange, officer);

            return;
        }

        const statusChangeDialogConfig = STATUS_CHANGE_MESSAGES[statusChange.newStatus as OfficerStatus];
        const config: MatDialogConfig = {
            data: {
                titleText: this.getTranslationIfExists(statusChangeDialogConfig?.title, {
                    fullName: this.getOfficersFullName(officer),
                }),
                confirmationText: this.getTranslationIfExists(statusChangeDialogConfig?.confirmation),
                confirmButtonLabel: this.getTranslationIfExists(statusChangeDialogConfig?.confirmButtonLabel),
                declineButtonLabel: this.getTranslationIfExists(statusChangeDialogConfig?.declineButtonLabel),
                theme: statusChangeDialogConfig?.theme,
            },
            disableClose: true,
        };

        this.dialogService
            .open(ConfirmationDialogComponent, config)
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() =>
                this.statusChange.emit({
                    id: officer.id,
                    ...statusChange,
                })
            );
    }

    protected promoteOfficer(officer: OfficerEntity): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("uavIdAdminLibOfficer.officerList.promoteOfficerTitle", {
                        fullName: this.getOfficersFullName(officer),
                    }),
                    confirmationText: this.translocoService.translate("uavIdAdminLibOfficer.officerList.genericConfirmationMessage"),
                    confirmButtonLabel: this.translocoService.translate(
                        "uavIdAdminLibOfficer.officerList.promoteOfficerConfirmButtonLabel"
                    ),
                    declineButtonLabel: this.translocoService.translate("uavIdAdminLibOfficer.officerList.declineButtonLabel"),
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.officerPromotion.emit(officer));
    }

    protected demoteOfficer(officer: OfficerEntity): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("uavIdAdminLibOfficer.officerList.demoteOfficerTitle", {
                        fullName: this.getOfficersFullName(officer),
                    }),
                    confirmationText: this.translocoService.translate("uavIdAdminLibOfficer.officerList.genericConfirmationMessage"),
                    confirmButtonLabel: this.translocoService.translate("uavIdAdminLibOfficer.officerList.demoteOfficerConfirmButtonLabel"),
                    declineButtonLabel: this.translocoService.translate("uavIdAdminLibOfficer.officerList.declineButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.officerDemotion.emit(officer));
    }

    protected updateOfficerUnit(officer: OfficerEntity) {
        this.dialogService
            .open(AssignOfficerUnitDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("uavIdAdminLibOfficer.officerList.updateOfficerUnitTitle", {
                        fullName: this.getOfficersFullName(officer),
                    }),
                    confirmationText: this.translocoService.translate("uavIdAdminLibOfficer.officerList.updateOfficerUnitConfirmationText"),
                    confirmButtonLabel: this.translocoService.translate(
                        "uavIdAdminLibOfficer.officerList.updateOfficerUnitConfirmButtonLabel"
                    ),
                    declineButtonLabel: this.translocoService.translate(
                        "uavIdAdminLibOfficer.officerList.updateOfficerUnitDeclineButtonLabel"
                    ),
                    officerUnit: officer.officerUnit,
                    officerUnits: this.localStore.selectSnapshotByKey("officerUnits"),
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((officerUnit) => this.officerUnitChange.emit({ officerId: officer.id, officerUnit }));
    }

    private activateAndAssignUnitToOfficer(statusChange: StatusChange, officer: OfficerListItem): void {
        const config: MatDialogConfig = {
            data: {
                titleText: this.translocoService.translate("uavIdAdminLibOfficer.officerList.activateOfficerAndAssignUnitTitle", {
                    fullName: this.getOfficersFullName(officer),
                }),
                confirmationText: this.translocoService.translate(
                    "uavIdAdminLibOfficer.officerList.activateOfficerAndAssignUnitConfirmationMessage"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "uavIdAdminLibOfficer.officerList.activateOfficerAndAssignUnitConfirmButtonLabel"
                ),
                declineButtonLabel: this.translocoService.translate("uavIdAdminLibOfficer.officerList.declineButtonLabel"),
                officerUnit: officer.officerUnit,
                officerUnits: this.localStore.selectSnapshotByKey("officerUnits"),
                isInitialAssign: true,
            },
            disableClose: true,
        };

        this.dialogService
            .open(AssignOfficerUnitDialogComponent, config)
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((officerUnit) =>
                this.statusChange.emit({
                    id: officer.id,
                    officerUnit: officerUnit,
                    ...statusChange,
                })
            );
    }

    private setInitialSort(): void {
        const initialSort = this.localStore.selectSnapshotByKey("sort");

        if (!initialSort) {
            return;
        }

        this.matSort.active = initialSort.active;
        this.matSort.direction = initialSort.direction;
    }

    private getTranslationIfExists(key?: string, params?: HashMap): string | undefined {
        return key ? this.translocoService.translate(key, { ...params }) : undefined;
    }

    private getOfficersFullName(officer: OfficerListItem | OfficerEntity): string {
        return `${officer.firstName} ${officer.lastName}`;
    }
}
