import { DmsCoordinatesUtils, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { RemoteId } from "remote-id";

export function convertRemoteIdPositionToDmsString({ longitude, latitude }: RemoteId.Position): string {
    const latitudeDmsWithDirectionString = DmsCoordinatesUtils.convertDmsWithDirectionToString(
        DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(latitude, GeographicCoordinatesType.Latitude)
    );
    const longitudeDmsWithDirectionString = DmsCoordinatesUtils.convertDmsWithDirectionToString(
        DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(longitude, GeographicCoordinatesType.Longitude)
    );

    return `${latitudeDmsWithDirectionString} ${longitudeDmsWithDirectionString}`;
}
