import { HttpParams } from "@angular/common/http";
import { Params } from "@angular/router";
import { OfficerUnitEntity } from "../../shared/models/user.models";
import { InvolvementType, ReportStatusChange } from "../models/report-management.models";

export namespace ReportManagementActions {
    export class GetReports {
        public static readonly type = "[ReportManagement] Get reports";
        constructor(public involvementType: InvolvementType, public queryParams?: Params) {}
    }

    export class GetReportSummary {
        public static readonly type = "[ReportManagement] Get report summary";
        constructor(public reportId: string) {}
    }

    export class GetOfficers {
        public static readonly type = "[ReportManagement] Get officers";
    }

    export class GetActiveOfficers {
        public static readonly type = "[ReportManagement] Get active officers";
        constructor(public officerUnitId: string) {}
    }

    export class UpdateAssignedOfficer {
        public static readonly type = "[ReportManagement] Update assigned officer";
        constructor(public reportId: string, public officerId: string) {}
    }

    export class UpdateInterventionNote {
        public static readonly type = "[ReportManagement] Update intervention note";
        constructor(public reportId: string, public note: string) {}
    }

    export class UpdateReportStatus {
        public static readonly type = "[ReportManagement] Update report status";
        constructor(public statusChange: ReportStatusChange) {}
    }

    export class UpdateOfficerUnit {
        public static readonly type = "[ReportManagement] Update officer unit";
        constructor(public reportId: string, public officerUnit: OfficerUnitEntity) {}
    }

    export class GetReportGeoZonesInfo {
        public static readonly type = "[ReportManagement] Get report geo zones info";
        constructor(public params: HttpParams) {}
    }

    export class GetReportGeoZoneDetails {
        public static readonly type = "[ReportManagement] Get report geo zone details";
        constructor(public zoneId: string) {}
    }
}
