<h3>
    <dtm-ui-icon name="user"></dtm-ui-icon>
    {{ "uavIdSharedLibReport.reportPreviewUser.header" | transloco }}
    <ng-content select="[headerAdditionalContent]"></ng-content>
</h3>

<div *ngIf="user$ | ngrxPush as user" class="split-content-container">
    <div>
        <div class="label">{{ "uavIdSharedLibReport.reportPreviewUser.fullNameLabel" | transloco }}</div>
        <div class="value">{{ user?.fullName ?? "-" }}</div>
    </div>
    <div>
        <div class="label">
            {{ "uavIdSharedLibReport.reportPreviewUser.phoneNumberLabel" | transloco }}
        </div>
        <div class="value">{{ (user?.phoneNumber | formatPhoneNumber) ?? "-" }}</div>
    </div>
    <div>
        <div class="label">
            {{ "uavIdSharedLibReport.reportPreviewUser.allReportsLabel" | transloco }}
        </div>
        <div class="value"><dtm-ui-icon name="stack-line"></dtm-ui-icon>{{ user?.numberOfReports ?? "-" }}</div>
    </div>
    <div>
        <div class="label">
            {{ "uavIdSharedLibReport.reportPreviewUser.rejectedReportsLabel" | transloco }}
        </div>
        <div class="value">
            <dtm-ui-icon name="close-circle" class="rejected-reports-icon"></dtm-ui-icon>{{ user?.numberOfRejectedReports ?? "-" }}
        </div>
    </div>
</div>
