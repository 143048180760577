import { Injectable } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Page } from "@dtm-frontend/shared/ui";
import { OfficerEntity, OfficerStatus, OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, Observable, catchError, finalize, switchMap, tap } from "rxjs";
import { OfficerError, OfficerListItem } from "../models/officer.models";
import { OfficerApiService } from "../services/officer-api.service";
import { OfficerActions } from "./officer.actions";

export interface OfficerStateModel {
    loggedInOfficer: OfficerEntity | undefined;
    getLoggedInOfficerError: OfficerError | undefined;
    isProcessing: boolean;
    officers: OfficerListItem[];
    officersPage: Page | undefined;
    getOfficersError: OfficerError | undefined;
    officerUnits: OfficerUnitEntity[] | undefined;
    getOfficerUnitsError: OfficerError | undefined;
    uniformedServiceOfficerUnits: OfficerUnitEntity[] | undefined;
    getUniformedServiceOfficerUnitsError: OfficerError | undefined;
    statusChangeError: OfficerError | undefined;
    promoteOfficerError: OfficerError | undefined;
    demoteOfficerError: OfficerError | undefined;
    updateOfficerUnitError: OfficerError | undefined;
}

const DEFAULT_STATE: OfficerStateModel = {
    loggedInOfficer: undefined,
    getLoggedInOfficerError: undefined,
    isProcessing: false,
    officers: [],
    officersPage: undefined,
    getOfficersError: undefined,
    officerUnits: undefined,
    getOfficerUnitsError: undefined,
    uniformedServiceOfficerUnits: undefined,
    getUniformedServiceOfficerUnitsError: undefined,
    statusChangeError: undefined,
    promoteOfficerError: undefined,
    demoteOfficerError: undefined,
    updateOfficerUnitError: undefined,
};

@State<OfficerStateModel>({
    name: "officer",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class OfficerState {
    @Selector()
    public static isProcessing(state: OfficerStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static officers(state: OfficerStateModel): OfficerListItem[] {
        return state.officers;
    }

    @Selector()
    public static officersPage(state: OfficerStateModel): Page | undefined {
        return state.officersPage;
    }

    @Selector()
    public static getOfficersError(state: OfficerStateModel): OfficerError | undefined {
        return state.getOfficersError;
    }

    @Selector()
    public static officerUnits(state: OfficerStateModel): OfficerUnitEntity[] | undefined {
        return state.officerUnits;
    }

    @Selector()
    public static getOfficerUnitsError(state: OfficerStateModel): OfficerError | undefined {
        return state.getOfficerUnitsError;
    }

    @Selector()
    public static uniformedServiceOfficerUnits(state: OfficerStateModel): OfficerUnitEntity[] | undefined {
        return state.uniformedServiceOfficerUnits;
    }

    @Selector()
    public static getUniformedServiceOfficerUnitsError(state: OfficerStateModel): OfficerError | undefined {
        return state.getUniformedServiceOfficerUnitsError;
    }

    @Selector()
    public static statusChangeError(state: OfficerStateModel): OfficerError | undefined {
        return state.statusChangeError;
    }

    @Selector()
    public static promoteOfficerError(state: OfficerStateModel): OfficerError | undefined {
        return state.promoteOfficerError;
    }

    @Selector()
    public static demoteOfficerError(state: OfficerStateModel): OfficerError | undefined {
        return state.demoteOfficerError;
    }

    @Selector()
    public static updateOfficerUnitError(state: OfficerStateModel): OfficerError | undefined {
        return state.updateOfficerUnitError;
    }

    constructor(private readonly officerApiService: OfficerApiService, private readonly store: Store) {}

    @Action(OfficerActions.GetOfficers)
    public getAccountRequests(context: StateContext<OfficerStateModel>, action: OfficerActions.GetOfficers) {
        context.patchState({ isProcessing: true });

        return this.officerApiService.getOfficers(action.queryParams).pipe(
            tap(({ content, page }) => context.patchState({ officers: content, officersPage: page, getOfficersError: undefined })),
            catchError((error) => {
                context.patchState({ getOfficersError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OfficerActions.GetAllOfficerUnits)
    public getAllOfficerUnits(context: StateContext<OfficerStateModel>) {
        return this.officerApiService.getAllOfficerUnits().pipe(
            tap((officerUnits) => context.patchState({ getOfficerUnitsError: undefined, officerUnits })),
            catchError((error) => {
                context.patchState({ getOfficerUnitsError: error });

                return EMPTY;
            })
        );
    }

    @Action(OfficerActions.GetUniformedServiceOfficerUnits)
    public getUniformedServiceOfficerUnits(context: StateContext<OfficerStateModel>) {
        context.patchState({ getUniformedServiceOfficerUnitsError: undefined });

        return context.dispatch(new OfficerActions.GetLoggedInOfficerData()).pipe(
            switchMap(() => {
                const uniformedServiceId = context.getState().loggedInOfficer?.officerUnit.uniformedServiceId;

                return uniformedServiceId ? this.officerApiService.getUniformedServiceOfficerUnits(uniformedServiceId) : EMPTY;
            }),
            tap((uniformedServiceOfficerUnits) => context.patchState({ uniformedServiceOfficerUnits })),
            catchError((error) => {
                context.patchState({ getUniformedServiceOfficerUnitsError: error });

                return EMPTY;
            })
        );
    }

    @Action(OfficerActions.ChangeStatus)
    public changeStatus(context: StateContext<OfficerStateModel>, action: OfficerActions.ChangeStatus) {
        context.patchState({ isProcessing: true });

        const { newStatus, id, officerUnit } = action.statusChange;
        const requestCall: Observable<OfficerUnitEntity> = (() => {
            switch (newStatus as OfficerStatus) {
                case OfficerStatus.Active:
                    return this.officerApiService.activateOfficer(id, officerUnit);
                case OfficerStatus.Inactive:
                    return this.officerApiService.deactivateOfficer(id);
                case OfficerStatus.Rejected:
                    return this.officerApiService.rejectOfficer(id);
                case OfficerStatus.PendingActivation:
                    return EMPTY;
            }
        })();

        return requestCall.pipe(
            tap(() => context.patchState({ statusChangeError: undefined })),
            catchError((error) => {
                context.patchState({ statusChangeError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OfficerActions.PromoteOfficer)
    public promoteOfficer(context: StateContext<OfficerStateModel>, action: OfficerActions.PromoteOfficer) {
        context.patchState({ isProcessing: true });

        return this.officerApiService.promoteOfficer(action.officerId).pipe(
            tap(() => context.patchState({ promoteOfficerError: undefined })),
            catchError((error) => {
                context.patchState({ promoteOfficerError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OfficerActions.DemoteOfficer)
    public demoteOfficer(context: StateContext<OfficerStateModel>, action: OfficerActions.DemoteOfficer) {
        context.patchState({ isProcessing: true });

        return this.officerApiService.demoteOfficer(action.officerId).pipe(
            tap(() => context.patchState({ demoteOfficerError: undefined })),
            catchError((error) => {
                context.patchState({ demoteOfficerError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OfficerActions.UpdateOfficerUnit)
    public updateOfficerUnit(context: StateContext<OfficerStateModel>, { officerId, officerUnit }: OfficerActions.UpdateOfficerUnit) {
        context.patchState({ isProcessing: true, updateOfficerUnitError: undefined });

        return this.officerApiService.updateOfficerUnit(officerId, officerUnit).pipe(
            catchError((error) => {
                context.patchState({ updateOfficerUnitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OfficerActions.GetLoggedInOfficerData)
    private getLoggedInOfficerData(context: StateContext<OfficerStateModel>) {
        if (context.getState().loggedInOfficer) {
            return EMPTY;
        }

        context.patchState({ getLoggedInOfficerError: undefined });

        return this.officerApiService.getOfficerData(this.store.selectSnapshot(AuthState.userId)).pipe(
            tap((loggedInOfficer) => context.patchState({ loggedInOfficer })),
            catchError((error) => {
                context.patchState({ getLoggedInOfficerError: error });

                return EMPTY;
            })
        );
    }
}
