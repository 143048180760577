import { Coords, DomEvent, TileLayer, Util, WMSOptions } from "leaflet";

// NOTE: createTile based on https://github.com/Leaflet/Leaflet/blob/v1.7.1/src/layer/tile/TileLayer.js
export class AuthorizedTileLayerWMS extends TileLayer.WMS {
    constructor(url: string, options: WMSOptions, private authorizationHeader: string) {
        super(url, options);
    }

    public createTile(coords: Coords, done: (error?: Error, tile?: HTMLElement) => void): HTMLImageElement {
        const tile: HTMLImageElement = document.createElement("img");
        // eslint-disable-next-line no-underscore-dangle
        DomEvent.on(tile, "load", Util.bind(this._tileOnLoad, this, done, tile));
        // eslint-disable-next-line no-underscore-dangle
        DomEvent.on(tile, "error", Util.bind(this._tileOnError, this, done, tile));

        if (this.options.crossOrigin || this.options.crossOrigin === "") {
            tile.crossOrigin = this.options.crossOrigin === true ? "" : this.options.crossOrigin;
        }

        /*
         Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
         http://www.w3.org/TR/WCAG20-TECHS/H67
        */
        tile.alt = "";

        /*
         Set role="presentation" to force screen readers to ignore this
         https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
        */
        tile.setAttribute("role", "presentation");

        const headers = {
            Authorization: this.authorizationHeader,
        };

        const url: string = this.getTileUrl(coords);

        fetch(url, {
            headers: headers,
        })
            .then((response: Response) => {
                if (!response.ok) {
                    throw new Error();
                }

                return response.blob();
            })
            .then((blob: Blob) => {
                const imageUrl: string = URL.createObjectURL(blob);
                tile.src = imageUrl;
            })
            .catch((error: Error) => {
                done(error);
            });

        return tile;
    }
}
