<ng-container *ngrxLet="{ isProcessing: isProcessing$, zonesInfo: zonesInfo$, zonesError: zonesError$ } as vm">
    <dtm-ui-loader-container *ngIf="!!vm.zonesInfo.length" [isShown]="vm.isProcessing">
        <div class="panel">
            <div class="header">
                <h2>{{ "uavIdSharedLibReport.reportPreviewZonesInfo.title" | transloco }}</h2>

                <button type="button" class="button-icon" (click)="closePanel()">
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
            </div>

            <div class="content">
                <dtm-ui-info-message *ngIf="vm.zonesError; else zonesTemplate" type="error">
                    {{ "uavIdSharedLibReport.reportPreviewZonesInfo.dataError" | transloco }}
                </dtm-ui-info-message>

                <ng-template #zonesTemplate>
                    <ng-container *ngIf="isMobile$ | ngrxPush; else zonesTableTemplate">
                        <div *ngFor="let zone of vm.zonesInfo" class="zone-card">
                            <div class="cell-with-actions">
                                <dtm-ui-label-value
                                    [label]="'uavIdSharedLibReport.reportPreviewZonesInfo.designatorLabel' | transloco"
                                    [value]="zone.designator"
                                ></dtm-ui-label-value>
                                <ng-container *ngTemplateOutlet="actionsTemplate; context: { $implicit: zone }"></ng-container>
                            </div>
                            <dtm-ui-label-value
                                [label]="'uavIdSharedLibReport.reportPreviewZonesInfo.typeLabel' | transloco"
                                [value]="zone.type"
                            ></dtm-ui-label-value>
                            <dtm-ui-label-value
                                [label]="'uavIdSharedLibReport.reportPreviewZonesInfo.heightRangeLabel' | transloco"
                                [value]="zone.heightRange"
                            ></dtm-ui-label-value>
                        </div>
                    </ng-container>

                    <ng-template #zonesTableTemplate>
                        <table>
                            <thead>
                                <th>{{ "uavIdSharedLibReport.reportPreviewZonesInfo.designatorLabel" | transloco }}</th>
                                <th>{{ "uavIdSharedLibReport.reportPreviewZonesInfo.typeLabel" | transloco }}</th>
                                <th>{{ "uavIdSharedLibReport.reportPreviewZonesInfo.heightRangeLabel" | transloco }}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let zone of vm.zonesInfo">
                                    <td>{{ zone.designator }}</td>
                                    <td>{{ zone.type }}</td>
                                    <td>{{ zone.heightRange }}</td>
                                    <td><ng-container *ngTemplateOutlet="actionsTemplate; context: { $implicit: zone }"></ng-container></td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </dtm-ui-loader-container>

    <ng-template #actionsTemplate let-zone>
        <button type="button" class="button-icon" (click)="zoneDetailsOpen.emit(zone)">
            <dtm-ui-icon name="information"></dtm-ui-icon>
        </button>
    </ng-template>
</ng-container>
