import {
    AdjacentGroundAreaCharacteristicType,
    AirRiskMitigationType,
    AvoidingFlightsOverPeopleType,
    BasicDataModel,
    ContainmentLevelReachedType,
    ControlledGroundAreaCharacteristicType,
    GroundAreaCharacteristicType,
    GroundRiskMitigationType,
    LocalizationType,
    OperationalAirspaceClassType,
    Permit,
    RiskAssessmentReference,
    TacticalAirRiskMitigationType,
    UavDetailsModel,
} from "../../models/permits.models";
import { MissionType } from "../../models/tactical.models";
import { UavType } from "../../models/uav.models";

export const uavDetailsMock: UavDetailsModel = {
    uavModel: {
        id: "5c7825fb-3a9a-458d-89d8-cd4b27f7796c",
        manufacturer: "DJI",
        name: "Phantom 4 Pro",
        type: UavType.Multirotor,
        technicalSpecification: {
            maxDroneWidth: 2,
            takeOffMass: 2,
            maxFlightSpeed: 2,
        },
    },
    operatedNumber: 222,
    isDroneSwarm: true,
    serialNumbers: "SJFVGKJHY6785GHJH5",
    maxUavWidth: 33,
    takeOffWeight: 33,
    maxFlightSpeed: 33,
    additionalTechRequirements: "Dron musi mieć smak czekoladowy!",
    designVerificationReport: "Report",
    certificateOfAirworthiness: "KLJ765900",
    noiseCertificate: "CERT8765KFhg",
    riskMitigation: GroundRiskMitigationType.HighRobustness,
    comments: "Comment",
};

export const basicMock: BasicDataModel = {
    caaContactUser: {
        id: "156",
        firstName: "Matt",
        lastName: "Murdock",
        email: "matt@murdock.pl",
    },
    operator: {
        id: "e5555555-2222-3333-4444-222222222222",
        number: "POLjkiurloocsk45",
        name: "Phil Coulson",
    },
    operatorContact: {
        firstName: "Jan",
        lastName: "Jabłoński",
        phoneNumber: {
            countryCode: "PL",
            number: "721545454",
        },
        email: "waznyanalityk@blaba.pl",
        position: "Aalityk do spraw bardzo poważnych ale zupełnie niepotrzebnych",
    },
    operationRestrictions: {
        permitNumber: "234",
        expirationDate: {
            dateFrom: new Date("2023-05-01T22:00:00.000Z"),
            dateTo: new Date("2023-05-01T22:00:00.000Z"),
        },
        operationTarget: "csdfsdf",
        localization: LocalizationType.Dtm,
        kmlFile: [],
        locationDescription: "Dodatkowy opis lokalizacji.",
        dtm: {
            id: "5c7825fb-3a9a-458d-89d8-cd4b27f7796c",
            name: "DTM Nadarzyn",
        },
        riskMethodology: RiskAssessmentReference.SORA2_0,
        sail: 2,
        operationType: MissionType.VLOS,
        isDangerousGoodsTransport: true,
        pdraNumber: null,
    },
    groundRiskCharacteristic: {
        groundAreaCharacteristic: GroundAreaCharacteristicType.Controlled,
        controlledGroundAreaCharacteristic: ControlledGroundAreaCharacteristicType.Urban,
        finalGroundRiskClass: 3,
        adjacentGroundAreaCharacteristic: AdjacentGroundAreaCharacteristicType.Rural,
        adjacentGroundAreaExtent: 0.7,
    },
    operationalVolumeHeight: {
        operationalVolumeHeightLimit: 2222,
    },
    finalRiskArea: {
        operationArea: 2,
        adjacentArea: 2,
        operationalAirSpaces: [OperationalAirspaceClassType.D],
    },
    groundRiskMitigation: {
        strategicMitigationMeasure: GroundRiskMitigationType.HighRobustness,
        avoidingFlightsOverPeople: AvoidingFlightsOverPeopleType.LowRobustness,
        emergencyResponsePlan: GroundRiskMitigationType.Absence,
    },
    airRiskMitigation: {
        strategicMitigationMeasure: AirRiskMitigationType.CommonRulesAndAirspace,
        tacticalAirRiskMitigationMeasure: TacticalAirRiskMitigationType.BVlosArcA,
        containmentReachedLevel: ContainmentLevelReachedType.HIGHER,
        competencies: [
            {
                id: "5c7825fb-3a9a-458d-89d8-cd4b27f7796c",
                name: "A1",
            },
        ],
        additionalCompetencies: ["Umiejętność latania w czasie kichania"],
        staffCompetencies: ["Umiejętność latania na miotle"],
    },
    additionalAccidentType: {
        hasInsurance: true,
        operationsManualReference: "qweqwe",
        additionalLimitations: "<p>Lorem ipsum <b>dolor</b> sit amet.</p> <ul><li>opcja 1</li><li>opcja 2</li></ul>",
    },
};

export const permitMock: Permit = {
    basicData: basicMock,
    uavDetails: uavDetailsMock,
};
