import { NgIf } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { DeviceOrientationService } from "../shared/services/device-orientation.service";
import { GeolocationService } from "../shared/services/geolocation.service";
import { AZURE_MAPS_SUBSCRIPTION_KEY } from "../shared/shared-map.tokens";
import { LeafletContextMenuComponent } from "./components/leaflet-context-menu/leaflet-context-menu.component";
import { LeafletMapLayerSmoothTransitionDirective } from "./components/leaflet-map-layer/leaflet-map-layer-smooth-transition.directive";
import { LeafletMapLayerDirective } from "./components/leaflet-map-layer/leaflet-map-layer.directive";
import { LeafletMapComponent } from "./components/leaflet-map/leaflet-map.component";
import { LeafletMarkerDirective } from "./components/leaflet-marker/leaflet-marker.directive";
import { LeafletTooltipContentDirective } from "./components/leaflet-tooltip-content/leaflet-tooltip-content.directive";
import { LeafletUserPositionComponent } from "./components/leaflet-user-position/leaflet-user-position.component";
import { LEAFLET_MAP_CONFIG } from "./leaflet-map.tokens";

@NgModule({
    imports: [NgIf, MatTooltipModule, LetModule, PushModule, RouterModule, SharedI18nModule, SharedUiModule, MatMenuModule],
    declarations: [
        LeafletMapComponent,
        LeafletMapLayerDirective,
        LeafletMarkerDirective,
        LeafletTooltipContentDirective,
        LeafletUserPositionComponent,
        LeafletMapLayerSmoothTransitionDirective,
        LeafletContextMenuComponent,
    ],
    providers: [
        DeviceOrientationService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmMapLeaflet",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [
        LeafletMapComponent,
        LeafletMapLayerDirective,
        LeafletMarkerDirective,
        LeafletTooltipContentDirective,
        LeafletUserPositionComponent,
        LeafletMapLayerSmoothTransitionDirective,
        LeafletContextMenuComponent,
    ],
})
export class LeafletMapModule {
    public static forTest(azureMapsSubscriptionKey: string): ModuleWithProviders<LeafletMapModule> {
        return {
            ngModule: LeafletMapModule,
            providers: [
                {
                    provide: LEAFLET_MAP_CONFIG,
                    useValue: {
                        defaultPosition: {
                            lat: 52.237,
                            lng: 21.017,
                        },
                        zoom: {
                            min: 3,
                            max: 18,
                            initial: 7,
                            userPosition: 16,
                        },
                    },
                },
                {
                    provide: GeolocationService,
                    useValue: {
                        getUserPosition: () => Promise.resolve({ accuracy: 0, latitude: 51.248738, longitude: 22.50821 }),
                    },
                },
                {
                    provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                    useValue: azureMapsSubscriptionKey,
                },
            ],
        };
    }
}
