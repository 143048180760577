import { SortDirection } from "@angular/material/sort";
import { WGS84Coordinates } from "@dtm-frontend/shared/map/leaflet";
import { Page, PhoneNumber } from "@dtm-frontend/shared/ui";
import { RemoteId } from "remote-id";
import { Flight } from "../../flight/models/flight.models";
import { StatusChange } from "../../shared/models/status-badge.models";
import { OfficerUnitEntity, UserEntity } from "../../shared/models/user.models";
import { ReportReason, ReportStatus } from "./report-shared.models";

export interface ReportListItem {
    id: string;
    status: ReportStatus;
    number: string;
    reportedAt: Date;
    phoneNumber: PhoneNumber;
    location: string;
    isCreatedByOfficer: boolean;
    isEmergencyReport: boolean;
    interventionNote: string;
    assignedOfficer?: UserEntity;
    officerUnit: OfficerUnitEntity;
}

export interface ReportList {
    content: ReportListItem[];
    page: Page;
}

export interface ReportListQueryParams {
    text: string;
    statuses: string;
    createdDateFrom: string;
    createdDateTo: string;
    isCreatedByOfficer?: string;
    isEmergencyReport?: string;
    officerUnits?: string[];
    page: string;
    size: string;
    active: "number" | "reportedAt" | "status";
    direction: SortDirection;
}

export interface ReportManagementError {
    type: ReportManagementErrorType;
}

export enum ReportManagementErrorType {
    GetList = "ReportManagementErrorGetList",
    GetReportSummary = "ReportManagementErrorGetReportSummary",
    Unknown = "ReportManagementErrorUnknown",
}

export interface ReportListFilters {
    text: string | null;
    statuses: ReportStatus[] | null;
    createdDateFrom: Date | null;
    createdDateTo: Date | null;
    isCreatedByOfficer?: boolean | null;
    isEmergencyReport?: boolean | null;
    officerUnits?: string[] | null;
}

export interface ReportSummary {
    report: Report;
    flights: Flight[];
    remoteIds: Array<Partial<RemoteId.Data>>;
}

export interface Report {
    id: string;
    number: string;
    status: ReportStatus;
    createdAt: Date;
    uav: {
        location: WGS84Coordinates;
        height: string;
    };
    address: string;
    userLocation: WGS84Coordinates;
    pilot: {
        isVisible: boolean;
        description: string;
    };
    reportingUser: ReportingUser;
    reasons: ReportReason[];
    otherReasons: string;
    flightsNumber: number;
    flightsSearchRadius: number;
    interventionNote: string;
    assignedOfficer?: UserEntity;
    officerUnit: OfficerUnitEntity;
    isCreatedByOfficer: boolean;
    isEmergencyReport: boolean;
}

export interface ReportingUser {
    fullName?: string;
    phoneNumber?: PhoneNumber;
    numberOfReports?: number;
    numberOfRejectedReports?: number;
}

export interface ReportStatusChange extends StatusChange {
    id: string;
}

export enum InvolvementType {
    Assignee = "ASSIGNEE",
    Creator = "CREATOR",
    RegionHost = "REGION_HOST",
}

export interface ReportGeoZoneInfo {
    id: string;
    designator: string;
    type: string;
    heightRange: string;
}

export interface ReportGeoZoneDetails {
    contact: string;
    description: string;
}
