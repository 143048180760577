import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { LeafletFlightsLayerDetailsComponent } from "./components/leaflet-flights-layer/leaflet-flights-layer-details/leaflet-flights-layer-details.component";
import { LeafletFlightsLayerDirective } from "./components/leaflet-flights-layer/leaflet-flights-layer.directive";
import { StatusBadgeMenuComponent } from "./components/status-badge-menu/status-badge-menu.component";
import { StatusBadgeComponent } from "./components/status-badge/status-badge.component";

@NgModule({
    imports: [CommonModule, MatMenuModule, LetModule, PushModule, SharedI18nModule, SharedUiModule],
    declarations: [LeafletFlightsLayerDetailsComponent, LeafletFlightsLayerDirective, StatusBadgeComponent, StatusBadgeMenuComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdSharedLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [LeafletFlightsLayerDirective, StatusBadgeComponent, StatusBadgeMenuComponent],
})
export class UavIdentificationSharedModule {
    public static forTest(): ModuleWithProviders<UavIdentificationSharedModule> {
        return {
            ngModule: UavIdentificationSharedModule,
        };
    }
}
